import {useState} from 'react';
import useMixedPersons from "./useMixedPersons";

const useMixedSelect = (personList) => {
    const [mixedId, setMixedId] = useState({
        customer_id: "",
        representative_customer_id: "",
        builder_id: "",
        representative_builder_id: "",
        documentation_id: "",
        representative_documentation_id: "",
    });

    const handleMixedOnChange = (event) => {
        const {name, value} = event.target;
        const splitedValue = value.split("_");
        setMixedId(prev =>
            ({...prev, [name]: splitedValue[0], ["representative_" + name]: splitedValue[1]})
        );
    }

    const {
        responsiblePerson: customer, representativePerson: customerRepresentative
    } = useMixedPersons(mixedId.customer_id, mixedId.representative_customer_id, personList);

    const {
        responsiblePerson: builder, representativePerson: builderRepresentative
    } = useMixedPersons(mixedId.builder_id, mixedId.representative_builder_id, personList);

    const {
        responsiblePerson: documentation, representativePerson: documentationRepresentative
    } = useMixedPersons(mixedId.documentation_id, mixedId.representative_documentation_id, personList);

    const isSelectedMixed =
        customerRepresentative &&
        builderRepresentative &&
        documentationRepresentative

    return {
        mixedState: {
            customer,
            customerRepresentative,
            builder,
            builderRepresentative,
            documentation,
            documentationRepresentative,
        },
        handleMixedOnChange,
        mixedId,
        isSelectedMixed
    };
};

export default useMixedSelect;