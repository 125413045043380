import React, {useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {Box, Button, ButtonGroup, Typography} from "@mui/material";

const CustomTariffProposeModal = (props) => {
    const methods = useForm();
    const {setValue} = methods;

    return (
        <FormProvider {...methods} >
            <form onSubmit={methods.handleSubmit(props.onSubmit)}>
                <Box>
                    <Typography>Вы
                        передаете управление тарифом Администратору компании, данное действие может привести к
                        увеличению стоимости тарифа
                    </Typography>


                    <Box className="flex justify-center">
                        <ButtonGroup>
                            <Button
                                type='submit'
                                color="error"
                                onClick={() => setValue("is_administrator_allowed_edit_tariff", false)}
                                variant="outlined">Отказаться</Button>
                            <Button
                                type='submit'
                                color="success"
                                onClick={() => setValue("is_administrator_allowed_edit_tariff", true)}
                                variant="outlined">Согласен</Button>
                        </ButtonGroup>
                    </Box>
                </Box>
            </form>
        </FormProvider>
    );
};

export default CustomTariffProposeModal;