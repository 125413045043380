import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {CookiesProvider} from "react-cookie";

const theme = createTheme({
    palette: {
        primary: {
            main: '#0f1937',  // основной цвет
        },
        // secondary: {
        //     main: '#dc004e',  // дополнительный цвет
        // },
        // background: {
        //     default: '#f5f5f5',  // цвет фона
        // },
        // text: {
        //     primary: '#333',  // цвет основного текста
        // },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <CssBaseline/> {/* Это сбрасывает стандартные стили */}
        <CookiesProvider defaultSetOptions={{path: '/'}}>
            <App/>
        </CookiesProvider>
    </ThemeProvider>
);

reportWebVitals();
