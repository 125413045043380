import axios from "axios";
// import paymentDetailsFake from "./paymentDetailsFake.json";

export const systemTariffs = async (userSettings) => {

    try {

        const response = await axios.get(
            `${userSettings.api}/tariffs/`,
            userSettings.headers.get
        );

        // console.log('\n response', response.data);

        return response.data;
    } catch (error) {
        console.log('\n systemTariffs', error);
    }
}

export const getTariffsCompanies = async (ids, userSettings) => {
    try {
        const requests = ids.map(async (id) => {
            try {
                const response = await axios.get(
                    `${userSettings.api}/tariffs/companies/${id}`,
                    userSettings.headers.get
                );
                return { id, tariff: response.data, error: null };
            } catch (error) {
                if (error.response) {
                    // Сервер ответил с кодом ошибки
                    return {
                        id,
                        tariff: null,
                        error: {
                            status: error.response.status,
                            data: error.response.data
                        }
                    };
                } else {
                    return {
                        id,
                        tariff: null,
                        error: {
                            message: error.message
                        }
                    };
                }
            }
        });

        const responses = await Promise.all(requests);

        // console.log('\n responses', responses);

        return responses;
    } catch (error) {
        console.log('\n getTariffsCompanies', error);
    }
};

export const setTariffsCompany = async (company_id, tariff_id, userSettings) => {
    try {

        const formData = new FormData();
        formData.append("company", company_id);
        formData.append("tariff", tariff_id);

        const response = await axios.post(
            `${userSettings.api}/tariffs/companies/${company_id}/create_company_tariff/`,
            formData,
            {headers: userSettings.headers.postForm}
        );

        if (response.data) return response.data;

    } catch (error) {
        console.log('\n getTariffsCompanies', error);
    }
};

export const paymentDetails = async (userSettings) => {

    try {

        const response = await axios.get(
            `${userSettings.api}/payments/get_superuser_bank_details/`,
            userSettings.headers.get
        );

        // console.log('\n response', response.data);

        return response.data;
    } catch (error) {
        console.log('\n systemTariffs', error);
    }
}

export const generateGUID = async (userSettings) => {
    // return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    //     var r = Math.random() * 16 | 0,
    //         v = c === 'x' ? r : ((r & 0x3) | 0x8); // Добавлены скобки
    //     return v.toString(16);
    // });

    try {

        const response = await axios.get(
            `${userSettings.api}/functools/generate_guid/`,
            userSettings.headers.get
        );
        // console.log('\n response', response.data);
        return(response.data.result);

    } catch (error) {

        console.log('\n generateGUID', error);
    }
}

export const getPaymentAmount = async (company_id, userSettings) => {

    try {

        const response = await axios.get(
            `${userSettings.api}/tariffs/companies/${company_id}/get_payment_difference/`,
            userSettings.headers.get
        );
        // console.log('\n response', response.data);
        return(response.data);

    } catch (error) {

        console.log('\n getPaymentAmount', error);
    }
}

export const saveBill = async (company_id, bill, billName, amount, userSettings) => {

    try {

        // console.log('\n saveBill', company_id, bill, billName, amount, userSettings);

        const formData = new FormData();
        formData.append('amount', amount);
        formData.append('bill', bill, billName);

        const response = await axios.post(
            `${userSettings.api}/company/${company_id}/send_payment_request/`,
            formData,
            {headers: userSettings.headers.postForm}
        );

        // console.log('\n res', response.data);
        return {
            success: !!response.data
        };
    }
    catch (error) {

        console.log('\n saveBill', error);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
}