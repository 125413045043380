import React, {useContext, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import RegistrationInput from "../../UI/Inputs/RegistrationInput";
import Btn from "../../UI/Buttons/Btn";
import UserContext from "../../../store/user/user-context";
import {useLocation} from "react-router-dom";
import {Alert, Box, Button} from "@mui/material";
import axios from "axios";

export default function TaskEngineer(
    {
        createOrder,
        sendGipOrderHandler,
        order,
        editGipOrderHandler,
        moveGipOrderHandler,
        handleClosePopup,
        setGipOrders
    }
) {
    const userSettings = useContext(UserContext);
    const company_id = +window.localStorage.getItem('company');
    const project_id = +window.localStorage.getItem('project');
    const mapSettings = new URLSearchParams(useLocation().search);
    const building_id = +mapSettings.get('building');

    const methods = useForm();
    const role = +window.localStorage.getItem('role');

    const [state, setState] = useState(null);
    const [isCollapsed, setIsCollapsed] = useState(true);

    // console.log(`\n createOrder`, createOrder);
    if (order) console.log(`\n order`, order);

    const sendGipOrder = (data) => {
        data.coordinate_x = createOrder[0];
        data.coordinate_y = createOrder[1];

        console.log(`\n data`, data);
        sendGipOrderHandler(data);
    }
    const editGipOrder = (data) => {

        data.coordinate_x = order.coordinate_x;
        data.coordinate_y = order.coordinate_y;
        data.floor = order.floor;
        data.id = order.id;

        // console.log(`\n data`, data);
        editGipOrderHandler(data);
    }
    const moveGipOrder = () => {
        console.log(order)
        moveGipOrderHandler(order.id, "gip");
        handleClosePopup();
    }

    const sendCommentByManager = async (data) => {
        const formData = new FormData();
        formData.append("comment", data.comment);
        for (let i = 0; i < data.photos_to_upload.length; i++) {
            const file = data.photos_to_upload[i];
            formData.append('photos_to_upload', file);
        }

        const sendMessage = await axios.post(
            `${userSettings.api}/building-plans/companies/${company_id}/projects/${project_id}/buildings/${building_id}/sections/${order.section}/plans/${order.id}/send_message/`,
            formData,
            {headers: userSettings.headers.postForm}
        );

        const newComment = sendMessage.data;
        console.log(newComment)
        console.log(setGipOrders)
        if (role === 5) {
            setGipOrders(gipOrder => {
                return {...gipOrder, history: [...gipOrder.history, newComment]};
            })
        } else {
            setGipOrders(prev => {
                return prev.map(gipOrder => {
                    if (gipOrder.id === order.id) {
                        return {...gipOrder, history: [...gipOrder.history, newComment]};
                    } else return gipOrder;
                })
            })
        }

        handleClosePopup()
    }

    const acceptGipOrder = async () => {
        const accept = await axios.post(
            `${userSettings.api}/building-plans/companies/${company_id}/projects/${project_id}/buildings/${building_id}/sections/${order.section}/plans/${order.id}/accept/`,
            null,
            {headers: userSettings.headers.postForm}
        );

        console.log(accept)
        setGipOrders(prev => prev.map(gip => {
            if (gip.id === order.id) {
                return {...order, status: "completed"}
            } else return gip;
        }));

        handleClosePopup();
    }

    // /building-plans/companies/{company_id}/projects/{project_id}/buildings/{building_id}/sections/{section_id}/plans/{building_plan_id}/accept/

    if (!order) return (<FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(sendGipOrder)}>
            <RegistrationInput
                name={`comment`}
                type={`text`}
                label={'Комментарий к задаче'}
            />
            <RegistrationInput
                name={`photos_to_upload`}
                type={`file`}
                label={'Добавить фотографии'}
                multiple
                accept='.jpg,.jpeg,.png,.gif'
                allowedFileTypes='.jpg, .jpeg, .png, .gif'
            />
            <div className={`mx-auto w-max`}>
                <Button variant="contained" type="submit">Записать предписание</Button>
            </div>
        </form>
    </FormProvider>);
    else return (!state
            ?
            <React.Fragment>
                <div className={`flex flex-col gap-2 items-center`}>
                    {order.status !== "completed" &&
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(sendCommentByManager)}>
                                <RegistrationInput
                                    name={`comment`}
                                    type={`text`}
                                    label={'Комментарий к задаче'}
                                />
                                <RegistrationInput
                                    name={`photos_to_upload`}
                                    type={`file`}
                                    label={'Добавить фотографии'}
                                    multiple
                                    accept='.jpg,.jpeg,.png,.gif'
                                    allowedFileTypes='.jpg, .jpeg, .png, .gif'
                                />
                                <Box className="mx-auto w-max">
                                    <Button
                                        variant="contained"
                                        type="submit"
                                    >
                                        Написать комментарий для {role === 4 ? "ГИП" : "нач. участка"}
                                    </Button>
                                </Box>
                            </form>
                        </FormProvider>}
                    {/*<Btn center method={'edit'} color={`button`} onClick={() => setState('edit')}>Редактировать</Btn>*/}
                    {role === 4 && order.status !== "completed" &&
                        <Btn center icon={<svg fill="currentColor" viewBox="0 0 16 16" height="1em" width="1em">
                            <path fillRule="evenodd"
                                  d="M7.646.146a.5.5 0 01.708 0l2 2a.5.5 0 01-.708.708L8.5 1.707V5.5a.5.5 0 01-1 0V1.707L6.354 2.854a.5.5 0 11-.708-.708l2-2zM8 10a.5.5 0 01.5.5v3.793l1.146-1.147a.5.5 0 01.708.708l-2 2a.5.5 0 01-.708 0l-2-2a.5.5 0 01.708-.708L7.5 14.293V10.5A.5.5 0 018 10zM.146 8.354a.5.5 0 010-.708l2-2a.5.5 0 11.708.708L1.707 7.5H5.5a.5.5 0 010 1H1.707l1.147 1.146a.5.5 0 01-.708.708l-2-2zM10 8a.5.5 0 01.5-.5h3.793l-1.147-1.146a.5.5 0 01.708-.708l2 2a.5.5 0 010 .708l-2 2a.5.5 0 01-.708-.708L14.293 8.5H10.5A.5.5 0 0110 8z"/>
                        </svg>} color={`button`} onClick={moveGipOrder}>
                            Передвинуть
                        </Btn>
                    }

                    {role === 4 && order.status !== "completed" &&
                        <Button
                            variant="contained"
                            color="success"
                            onClick={acceptGipOrder}
                        >
                            Принять
                        </Button>
                    }

                    {order.status === "completed" &&
                        <Alert severity="success">Задача принята</Alert>
                    }

                    {(isCollapsed) ?
                        order.history &&
                        <Btn
                            method={`view`}
                            color={`button`}
                            center
                            onClick={() => setIsCollapsed(false)}
                        >
                            Посмотреть историю задачи
                        </Btn> :
                        <React.Fragment>
                            <ul className="w-full space-y-2">
                                {order.history.map((h, id) =>
                                    <li className="w-full border border-1 p-2 rounded" key={id}>

                                        <div className="flex flex-col gap-2">
                                            <p className="text-sm h-fit">{new Date(h.created_at).toLocaleString('ru-RU')}</p>
                                            <div className={`flex  flex-col gap-2`}>
                                                <p className="text-sm h-fit">{h.author.full_name}: </p>

                                                <p className="text-sm text-wrap">{h.comment}</p>
                                            </div>

                                            <div className="flex flex-wrap justify-center">
                                                {h.photos.map((photo, index) =>
                                                    <Box key={"photo" + index}>
                                                        <a
                                                            className={`rounded-md overflow-hidden shadow-md shadow-black/50`}
                                                            href={`${userSettings.fileServer}${photo.image}`}
                                                            target={`_blank`}
                                                            key={`${h.id}-${index}`}
                                                        >
                                                            <img
                                                                className="w-[80px] h-[80px]"
                                                                src={`${userSettings.fileServer}${photo.image}`}
                                                                alt={photo.order}
                                                            />
                                                        </a>
                                                    </Box>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                            <Btn method={`cancel`} color={`button`} center onClick={() => {
                                setIsCollapsed(!isCollapsed)
                            }}>Закрыть историю</Btn>
                        </React.Fragment>
                    }
                </div>
            </React.Fragment>
            :
            state === 'edit' && <React.Fragment>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(editGipOrder)}>
                        <RegistrationInput
                            name={`comment`}
                            type={`text`}
                            label={'Комментарий к задаче'}
                            defaultValue={order.comment}
                        />
                        <div className={`mx-auto w-max`}>
                            <Button type="submit">Записать предписание</Button>
                        </div>
                    </form>
                </FormProvider>
                <Btn method={'back'} color={`button`} center onClick={() => setState(null)}>Назад</Btn>
            </React.Fragment>
    )
        ;
}