import axios from "axios";
import {getSubcompanies} from "./getSubcompanies";

export const getOrders = async (
    userSettings,
    company_id,
    project_id,
) => {

    try {

        const subcompanies = await getSubcompanies(userSettings, company_id, project_id);
        // console.log('\n subcompanies', subcompanies);

        const buildings = await axios.get(
            `${userSettings.api}/tep-building/companies/${company_id}/projects/${project_id}/buildings/`,
            userSettings.headers.get
        );
        // console.log('\n buildings', buildings.data.data);

        const getSections = buildings?.data?.data?.map(async (building) => {

            const response = await axios.get(
                `${userSettings.api}/plans-configurator/companies/${company_id}/projects/${project_id}/building/${building.id}/sections/`,
                userSettings.headers.get
            )

            return (response.data)
        })
        const sections = (await Promise.all(getSections)).flat();
        // console.log('\n sections', sections);

        if (sections?.length > 0 && subcompanies?.length > 0) {

            const ordersByTenders = subcompanies?.map(async (subcompany) => {

                const ordersBySetions = sections.map( async (section) => {

                    const response = await axios.get(
                        `${userSettings.api}/company/${company_id}/projects/${project_id}/tenders/${subcompany.tender.id}/buildings/${section.building}/sections/${section.id}/orders/`,
                        userSettings.headers.get
                    )

                    return (response?.data?.data?.length > 0? response?.data?.data : null);
                });

                const orders = (await Promise.all(ordersBySetions)).flat().filter(o => o);

                return (orders);
            });
            const gotOrders = await Promise.all(ordersByTenders);
            // console.log('\n gotOrders', gotOrders);
            const orders = gotOrders.flat().map(order => {

                const subcompany = subcompanies.find(s => s.id === order.contract);
                // console.log('\n subcompany', subcompany);
                const building_id = sections.find(section => section.id === order.section).building;
                // console.log('\n building_id', building_id);

                return ({
                    ...order,
                    subcompany,
                    building_id,
                });
            });
            // console.log('\n orders', orders);
            return(orders);
        }

        if (!subcompanies && !buildings.data.data.length) return {answer: "Отсутствуют подрядчики и нет проектов - ТЕП"}
        else if (!buildings.data.data.length) return {answer: "Отсутствуют проекты - ТЕП"}
        else return {answer: "Отсутствуют подрядчики"};
    }
    catch (error) {
        console.log('\n error getOrders', error.response);
    }
};