import React, {useEffect} from 'react';
import KS2 from "../../../UI/PdfDocuments/KS-2";
import {pdf, PDFDownloadLink} from "@react-pdf/renderer";
import useFetchedInfo from "./hooks/useFetchedInfo";
import {Box, Button, Typography} from "@mui/material";

const GenerateKS2PDF = ({setKs2}) => {
    const subcompany_id = +window.localStorage.getItem('company');
    const tender_id = +window.localStorage.getItem('tender');

    const {fetchedInfo, isLoading} = useFetchedInfo(subcompany_id, tender_id);
    // console.log(fetchedInfo)

    useEffect(() => {
        if (!fetchedInfo) return;

        const blob = pdf((
            <KS2
                fetchedInfo={fetchedInfo}
            />
        )).toBlob().then(blob => setKs2(new File([blob], "KS-2.pdf")))
    }, [fetchedInfo]);

    return (
        <Box>
            <Typography variant="h1">КС-2:</Typography>
            {!isLoading && fetchedInfo &&
                <Box className='space-y-2 m-3 p-3 bg-[#e4e4e7] rounded-lg'>
                    <Box>
                        <PDFDownloadLink
                            document={
                                <KS2
                                    fetchedInfo={fetchedInfo}
                                />}
                            fileName={'KS-2'}

                        >
                            <Button variant="contained">Готово к загрузке!</Button>
                        </PDFDownloadLink>

                        {/*<PDFViewer style={{width: '100%', height: '100%'}}>*/}
                        {/*    <KS2*/}
                        {/*        fetchedInfo={fetchedInfo}*/}
                        {/*        customerState={customerState}*/}
                        {/*    />*/}
                        {/*</PDFViewer>*/}
                    </Box>
                </Box>
            }
        </Box>
    )
};

export default GenerateKS2PDF;
