import {Alert, Box, Button, FormControl, Input, InputAdornment, InputLabel} from "@mui/material";
import { useLocation } from "react-router-dom";
import PaymentRoundedIcon from "@mui/icons-material/PaymentRounded";
import React, {useContext, useState} from "react";
import {paymentCard} from "../../SendRequests/Payments";
import UserContext from "../../../store/user/user-context";

export default function CardPay() {

    const userSettings = useContext(UserContext);
    const data = new URLSearchParams(useLocation().search);
    const company_id = +data.get('company');
    const amount = +data.get('amount');

    const [message, setMessage] = useState(null);

    // console.log('\n ', company_id, amount);

    const paymentCardHandler = async (event) => {
        event.preventDefault(); // Предотвращаем перезагрузку страницы

        const userAmount = +event.target.amount.value;
        // console.log('\n paymentCardHandler', userAmount, company_id);
        
        const response = await paymentCard(userSettings, userAmount, company_id);
        if (response.success) {

            // console.log('\n response', response);
            window.location.replace(`/work/payment-company/?company=${company_id}&amount=${userAmount}&status=success`);
        }
        else {
            // console.log('\n response', response);

            setMessage(response.message)
        }

        // window.location.replace(`/work/payment-event/?company=${company_id}&amount=${userAmount}`);
    };

    // console.log('\n message', message);
    
    return (
        <Box className="flex items-center h-full">
            <Box className="w-max mx-auto">
                <h1>Оплата картой</h1>
                {message && <Alert severity="warning">{message}</Alert>}
                <form onSubmit={paymentCardHandler} className={`flex flex-col gap-2`}>
                    <FormControl sx={{ m: 1 }} variant="standard">
                        <InputLabel htmlFor="amount">Укажите сумму оплаты</InputLabel>
                        <Input
                            id="amount"
                            name={"amount"}
                            startAdornment={<InputAdornment position="start"><PaymentRoundedIcon /></InputAdornment>}
                            defaultValue={Math.ceil(amount)}
                            endAdornment={<InputAdornment position="end">р.</InputAdornment>}
                        />
                    </FormControl>
                    <Button type="submit" variant="outlined">Оплатить</Button>
                </form>
            </Box>
        </Box>
    );
}
