import React from 'react';
import {Box, Typography} from "@mui/material";
import {getLotsForRequest} from "../helpers/getLotsForRequest";

const TariffStats = ({prevAdded}) => {
    return (
        <Box>
            <Box>
                <Typography variant='h1'>Текущий тариф:</Typography>
                {prevAdded &&
                    getLotsForRequest(prevAdded, true).map(role =>
                        <Box key={role.name}>
                            <Typography>{role.name}: {role.value.count}</Typography>
                        </Box>
                    )
                }
            </Box>

            <Box>
                <Typography variant='h1'>Текущая занятость по ролям:</Typography>
                {prevAdded &&
                    getLotsForRequest(prevAdded, true).map(role =>
                        <Box key={role.name}>
                            <Typography>{role.name}: {role.value.used}</Typography>
                        </Box>
                    )
                }
            </Box>
        </Box>
    );
};

export default TariffStats;