import {getMinMaxDates} from "./getMinMaxDates";

export const validateWorkDatesAndNames = (qualityDocs, estimateElements, justNumeric = false) => {
    const rootDates = []
    qualityDocs.forEach(doc => {
        doc.guid_dates.forEach(date => rootDates.push(date));
    });

    const workDatesSet = new Set();
    const works = [];
    const workNamesSet = new Set();
    // console.log(qualityDocs)
    // console.log(estimateElements)
    estimateElements.estimate.forEach(est => {
        est.work_log.forEach(log => {
            if (rootDates.includes(log.id)) {
                workDatesSet.add(log.date);
                workNamesSet.add(est.name_works);
                if (works.filter(e => e.number === est.number).length === 0) works.push(est);
            }
        })
    })
    // console.log(works)

    const workDatesArray = Array.from(workDatesSet);
    const workNamesArray = Array.from(workNamesSet);

    return {
        works,
        workNames: workNamesArray,
        workDates: getMinMaxDates(workDatesArray, justNumeric),
        allDates: workDatesArray
    }
}