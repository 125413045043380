import axios from "axios";
import {Alert} from "@mui/material";

export const allWorkJournals = async (userSettings, company_id, project_id) => {

    try {

        const response = await axios.get(
            `${userSettings.api}/work-journal/companies/${company_id}/projects/${project_id}/all-work-journals`,
            userSettings.headers.get
        );

        if (response.data) {

            const journalsSSR = {};

            response.data.forEach((j) => {

                j.estimate.forEach(w => {

                    const logValuesSum = w?.work_log?.map(l => l.certificates_completed_work && +l.value).reduce((acc, val) => acc + val, 0);
                    const price = +w.unit_cost_smr + +w.unit_cost_materials;

                    if (!journalsSSR[w.ssr]) journalsSSR[w.ssr] = 0;

                    journalsSSR[w.ssr] += logValuesSum * price;
                });
            });

            return journalsSSR;
        }
    }
    catch (error) {

        console.log('\n allWorkJournals', error.response);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `company_id: ${company_id} project_id: ${project_id} attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
};

export const getFinancialPlan = async (userSettings, company_id, project_id) => {

    try {

        const journalsSSR = await allWorkJournals(userSettings, company_id, project_id);
        // console.log('\n journalsSSR', journalsSSR);
        // console.log('\n getFinancialPlan', userSettings, company_id, project_id);

        const response = await axios.get(
            `${userSettings.api}/finplan/companies/${company_id}/projects/${project_id}/`,
            userSettings.headers.get
        );
        const fp = {};
        Object.keys(response?.data?.financial_plan?.json)?.forEach(n => {

            const row = response?.data?.financial_plan?.json[n];
            const fact = journalsSSR && journalsSSR[row.ssr];
            
            fp[n] = {
                ...row,
                fact: fact > 0 ? fact : null,
            };
        })

        // console.log('\n fp', fp);
        return (fp);
    }
    catch (error) {

        if (error.response.data) {
            // console.log('\n ', error.response.data.errors[0].code);
            return (error.response.data.errors[0].code);
        } else {
            console.log('\n getFinancialPlan error', error.response);
        }
    }
}

export const saveFinancialPlan = async (userSettings, company_id, project_id, data) => {

    try {

        const response = await axios.post(
            `${userSettings.api}/finplan/companies/${company_id}/projects/${project_id}/`,
            {json: data},
            {headers: userSettings.headers.post}
        );
        // console.log('\n response', response.data);

        return ({
            success: response.status === 201,
            fp: response.data.financial_plan.json
        })
    }
    catch (error) {
        console.error('saveFinancialPlan', error);

        if (error.response.data) {

            return ({
                success: false,
                message: error?.response?.data.errors.map((e, i) => <Alert severity="error" key={i}>attr: {e.attr} detail: {e.detail} code: {e.code} </Alert>)
            })
        }
    }
}

export const putFinancialPlan = async (userSettings, company_id, project_id, data) => {

    try {

        // console.log('\n saveFinancialPlan', userSettings, company_id, project_id, data);

        const response = await axios.put(
            `${userSettings.api}/finplan/companies/${company_id}/projects/${project_id}/update-by-project/`,
            {json: data},
            { headers: userSettings.headers.post}
        );

        return ({
            success: response.status === 200,
            fp: response.data.financial_plan.json
        })
        // console.log('\n response', response.data);
    }
    catch (error) {
        console.error('saveFinancialPlan', error);

        if (error.response.data) {

            console.log('\n ', error?.response?.data.errors);

            return ({
                success: false,
                message: error?.response?.data.errors.map((e, i) => <Alert severity="error" key={i}>attr: {e.attr} detail: {e.detail} code: {e.code} </Alert>)
            })
        }
    }
}

export const getFP = async (userSettings, company_id, project_id) => {

    try {

        const response = await axios.get(
            `${userSettings.api}/finplan/companies/${company_id}/projects/${project_id}/`,
            userSettings.headers.get
        );

        // console.log('\n fp', fp);
        return (response?.data?.financial_plan?.json);
    }
    catch (error) {

        if (error.response.data) {
            // console.log('\n ', error.response.data.errors[0].code);
            return (error.response.data.errors[0].code);
        } else {
            console.log('\n getFinancialPlan error', error.response);
        }
    }
}
export const getWJ = async (userSettings, company_id, project_id) => {

    try {

        const response = await axios.get(
            `${userSettings.api}/work-journal/companies/${company_id}/projects/${project_id}/all-work-journals`,
            userSettings.headers.get
        );

        return (response?.data)
    }
    catch (error) {

        console.log('\n allWorkJournals', error.response);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `company_id: ${company_id} project_id: ${project_id} attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
};