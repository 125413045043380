import React, {useContext, useEffect, useState} from 'react';
import {Box, FormControlLabel, Switch, Typography} from "@mui/material";
import {getEstimateData} from "../../SendRequests/workJournalData";
import UserContext from "../../../store/user/user-context";
import {LineChart} from "@mui/x-charts/LineChart";
import tables from "../../UI/Tables/table.module.css";
import {Price} from "../../UI/Convertors/Price";

const ContractExecutionStatus = () => {

    const userSettings = useContext(UserContext);
    const subcompany_id = +window.localStorage.getItem('company');
    const tender_id = +window.localStorage.getItem('tender');

    const [works, setWorks] = useState(null);
    const [periods, setPeriods] = useState(null);
    const [sumPeriods, setSumPeriods] = useState(null);
    const [sumAllPeriods, setSumAllPeriods] = useState(null);
    const [approved, setApproved] = useState(false);

    // console.log('\n ', {
        // subcompany_id,
        // tender_id,
        // works,
        // periods,
        // sumPeriods,
        // sumAllPeriods,
    // });

    const series = [
        {
            id: 'plan',
            label: 'План',
            dataKey: 'plan',
            showMark: false,
            color: '#555f78'
        },
        {
            id: 'fact',
            label: 'Факт',
            dataKey: 'fact',
            showMark: false,
            color: '#5f7855'
        },
    ];
    const tableNames = [
        {
            name: "№",
            type: {span: 2},
            key: "number",
        },
        {
            name: "Наименование работ и затрат",
            type: {span: 2},
            key: "name_works",
        },
        {
            name: "Ед. изм.",
            type: {span: 2},
            key: "units",
        },
    ];
    if (periods) {

        // console.log('\n periods', periods);

        periods.forEach(date => {

            const months = [
                "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
                "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
            ];
            const month = months[new Date(date).getMonth()];
            const year = new Date(date).getFullYear();

            tableNames.push({
                name: (<React.Fragment>
                    <div>{month}</div>
                    <div>{year}</div>
                </React.Fragment>),
                type: {
                    plan: "План",
                    fact: "Факт",
                },
                date
            });
        })
    }

    const handleSwitchApproved = (event) => {
        setApproved(event.target.checked);
    };

    useEffect(() => {

        const getEstimate = async () => {
            const estimate = await getEstimateData(userSettings, subcompany_id, tender_id);

            if (estimate.success) {

                const approvedWorks = estimate.data
                    .map(w => {

                        const periods = w.periods.map(({ date, value }) => ({
                            date: new Date(date).toISOString().slice(0, 7) + '-01',
                            value
                        }));

                        const work_log = approved ?
                            w.work_log.filter(l => l.certificates_completed_work.length > 0) :
                            w.work_log
                        ;

                        const fact = Object.entries(
                                work_log
                                    .map(({ date, value }) => {
                                        const firstDay = new Date(date).toISOString().slice(0, 7) + '-01';
                                        return { firstDay, value: Number(value) };
                                    })
                                    .reduce((acc, { firstDay, value }) => {
                                        acc[firstDay] = (acc[firstDay] || 0) + value;
                                        return acc;
                                    }, {})
                            ).map(([date, value]) => ({ date, value }))
                        ;

                        // console.log('\n work_log', work_log);

                        return {
                            ...w,
                            work_log,
                            periods,
                            fact,
                        };
                    })
                    .filter(w => w.work_log.length > 0);
                setWorks(approvedWorks);

                const updatePeriods = [...new Set(
                    approvedWorks.flatMap(work => [
                        ...work.periods.map(p => p.date),
                        ...work.fact.map(f => f.date)
                    ])
                )].sort();
                setPeriods(updatePeriods);


                const updatesumPeriods = updatePeriods.map(date => {
                    const planSum = approvedWorks
                        .reduce((sum, work) => {
                            const period = work.periods.find(p => p.date === date);
                            return sum + (period ? period.value : 0);
                        }, 0)
                    ;

                    const factSum = approvedWorks
                        .reduce((sum, work) => {
                            const fact = work.fact.find(f => f.date === date);
                            return sum + (fact ? fact.value : 0);
                        }, 0)
                    ;

                    return { date, plan: planSum, fact: factSum };
                });
                setSumPeriods(updatesumPeriods);

                const updateSumAllPeriods = updatesumPeriods.reduce((acc, { date, plan, fact }, index) => {
                    const prev = acc[index - 1] || { plan: 0, fact: 0 }; 
                    acc.push({
                        date,
                        plan: plan + prev.plan, 
                        fact: fact + prev.fact  
                    });
                    return acc;
                }, []);
                setSumAllPeriods(updateSumAllPeriods);
            }
        };

        getEstimate();
    }, [userSettings, subcompany_id, tender_id, approved]);

    return (
        <Box>
            <Typography variant="h1">Статус исполнения договора</Typography>
            {/*<Alert className='w-fit' severity="error">?</Alert>*/}
            <FormControlLabel
                control={<Switch checked={approved} onChange={handleSwitchApproved} />}
                label={approved ? "Согласованные" : "Журнал работ"}
            />
            <Box className={`flex flex-col gap-2 h-full relative`}>
                <Box>
                    {sumAllPeriods?.length > 0 && <LineChart
                        dataset={sumAllPeriods.map(p => {

                            return ({
                                ...p,
                                date: new Date(p.date)
                            })
                        })}
                        xAxis={[
                            {
                                id: 'Years',
                                dataKey: 'date',
                                scaleType: 'time',
                                valueFormatter: (date) => date.toLocaleDateString("default", {
                                    year: "numeric",
                                    month: "short"
                                })
                            },
                        ]}
                        series={series}
                        height={300}
                        margin={{ left: 70 }}
                    />}
                </Box>
                {works && <Box className={`grow ${tables.container} h-[500px]`}>
                    <table className={tables.table}>
                        <thead>
                        <tr>
                            {tableNames.map((n, index) => (
                                <th
                                    key={index}
                                    rowSpan={n.type?.span}
                                    colSpan={!n.type?.span ? Object.keys(n.type).length : 0}
                                    className={`
                                    ${index === 0 ? "sticky left-0 z-30 bg-[#0F1937]  border border-amber-100" : ""}
                                    ${index === 1 ? "sticky left-[45px] z-30 bg-[#0F1937] border border-amber-100" : ""}
                               `}
                                >
                                    {n.name}
                                </th>
                            ))}
                        </tr>
                        <tr>
                            {tableNames
                                .filter(n => !n.type?.span)
                                .flatMap((n, index) =>
                                    Object.values(n.type).map((name, id) => (
                                        <th key={`${id}-${index}`}>
                                            {name}
                                        </th>
                                    ))
                                )
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {works?.map((w, index) => {

                            return (
                                <tr key={index}>
                                    {tableNames.map((n, id) => {

                                        const plan = (n.date && w.periods) && w.periods.find(p => p.date === n.date)?.value;
                                        const fact = (n.date && w.fact) && w.fact.find(p => p.date === n.date)?.value;

                                        return (
                                                n.date ?
                                                    <React.Fragment key={id}>
                                                        <td className={`text-center whitespace-nowrap`}>
                                                            {plan && Price(plan)}
                                                        </td>
                                                        <td className={`text-center whitespace-nowrap`}>
                                                            {fact && Price(fact)}
                                                        </td>
                                                    </React.Fragment> :
                                                    <td key={id}
                                                        className={`
                                                    ${n.key !== "name_works" ? "text-center" : "min-w-[350px]"}
                                                    ${id === 0 ? "sticky left-0" : ""}
                                                    ${id === 1 ? "sticky left-[45px]" : ""}
                                                    ${(index % 2 === 0) ? "bg-white" : "bg-[#E7E8EB]"}
                                                    `}>
                                                        {w[n.key]}
                                                    </td>
                                        );
                                    })}
                                </tr>
                            )
                        })}
                        <tr key={`all`}>
                            <td colSpan={2} className={"sticky left-0 bg-[#E7E8EB]"}>Итого за месяц</td>
                            <td></td>
                            {tableNames
                                .filter(n => n.date)
                                .map((n, id) => {

                                    const sumBydate = sumPeriods.find(p => p.date === n.date);
                                        return (
                                            <React.Fragment key={id}>
                                                <td className={`text-center whitespace-nowrap`}>{sumBydate?.plan > 0 &&
                                                    Price(sumBydate?.plan)
                                                }</td>
                                                <td className={`text-center whitespace-nowrap`}>{sumBydate?.fact > 0 &&
                                                    Price(sumBydate?.fact)
                                                }</td>
                                            </React.Fragment>
                                        )
                                    }
                                )
                            }
                        </tr>
                        <tr key={`all_summary`}>
                            <td colSpan={2} className={"sticky left-0 bg-white"}>Итого за период</td>
                            <td></td>
                            {tableNames
                                .filter(n => n.date)
                                .map((n, id) => {

                                    const sumBydate = sumAllPeriods.find(p => p.date === n.date);

                                        return (
                                            <React.Fragment key={id}>
                                                <td className={`text-center whitespace-nowrap`}>{sumBydate?.plan > 0 &&
                                                    Price(sumBydate?.plan)
                                                }</td>
                                                <td className={`text-center whitespace-nowrap`}>{sumBydate?.fact > 0 &&
                                                    Price(sumBydate?.fact)
                                                }</td>
                                            </React.Fragment>
                                        )
                                    }
                                )
                            }
                        </tr>
                        </tbody>
                    </table>
                </Box>}
            </Box>
        </Box>
    );
};

export default ContractExecutionStatus;