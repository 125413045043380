import React, {useEffect, useRef, useState} from 'react';
import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import View from 'ol/View';
import {Icon, Style} from 'ol/style';
import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import {defaults as defaultInteractions} from 'ol/interaction';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import 'ol/ol.css';
import Projection from 'ol/proj/Projection';
import OrderInfo from "./OrderInfo";
import * as olEasing from 'ol/easing';
import popupStyles from './popup_map.module.css';
import {ReactComponent as ClosePopup} from "../Icons/close.svg";
import CreateOrder from "./CreateOrder";
import {FullScreen, OverviewMap, ScaleLine, Zoom, ZoomSlider, ZoomToExtent} from "ol/control";
import "./plan.css";
import TaskEngineer from "../../Work/SectionManager/TaskEngineer";

export default function ViewingDrawings(
    {
        imageUrl,
        orders,
        fileServer,
        isCreateOrder,
        setIsCreateOrder,
        subcompanies,
        createOrderHandler,
        sendOrder,
        subcompanyOrder,
        signOrder,
        callToOrder,
        qualityControlOrder,
        rejectOrder,
        acceptOrder,
        isCreateGipOrder,
        setIsCreateGipOrder,
        sendGipOrderHandler,
        gipOrders,
        editGipOrderHandler,
        moveOrderHandler,
        isMoveGipOrder,
        setNewCoordinate,
        isMoveOrder,
        editOrder,
        allIdForQueries,
        setOrders,
        setGipOrders
    }
) {
    const mapRef = useRef(null);
    const popupRef = useRef(null);
    const [imageSize, setImageSize] = useState({width: 0, height: 0});
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedGipOrder, setSelectedGipOrder] = useState(null);
    const [createOrder, setCreateOrder] = useState(null);
    const [createGipOrder, setCreateGipOrder] = useState(null);

    const params = new URLSearchParams(document.location.search);
    const currentFloor = parseInt(params.get("floor"));
    const currentSectionId =  parseInt(params.get("section"));

    const handleClosePopup = () => {
        setSelectedOrder(null);
        if (setIsCreateOrder) setIsCreateOrder(null);
        setCreateOrder(null);

        setSelectedGipOrder(null);
        if (setIsCreateGipOrder) setIsCreateGipOrder(null);
        setCreateGipOrder(null);
    };

    useEffect(() => {
        if (!imageUrl) return;

        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
            setImageSize({width: image.width, height: image.height});
        };
    }, [imageUrl]);
    useEffect(() => {
        if (!mapRef.current || !imageSize.width || !imageSize.height) return;

        // console.log(`\n qualityControlOrder`, qualityControlOrder);
        const qualityControlOrderCoordinates = qualityControlOrder && [qualityControlOrder.coordinate_x, qualityControlOrder.coordinate_y];
        // console.log(`\n qualityControlOrderCoordinates`, qualityControlOrderCoordinates);

        const extent = [0, 0, imageSize.width, imageSize.height];
        const projection = new Projection({
            code: 'xkcd-image',
            units: 'pixels',
            extent: extent,
        });
        const map = new Map({
            target: mapRef.current,
            layers: [
                new ImageLayer({
                    source: new Static({
                        url: imageUrl,
                        projection: projection,
                        imageExtent: extent,
                    }),
                }),
            ],
            view: new View({
                projection: projection,
                center: qualityControlOrderCoordinates || [extent[2] / 2, extent[3] / 2],
                zoom: qualityControlOrderCoordinates ? 3.5 : 1.5,
                maxZoom: 5,
                minZoom: 1,
            }),
            interactions: defaultInteractions({pinchRotate: false}),
            controls: [
                new ScaleLine(),
                new FullScreen(),
                new OverviewMap({
                    layers: [
                        new ImageLayer({
                            source: new Static({
                                url: imageUrl,
                                projection: projection,
                                imageExtent: extent,
                            }),
                        }),
                    ],
                    collapsed: false
                }),
                new ZoomSlider(),
                new ZoomToExtent(),
                new Zoom()
            ]
        });

        const overlay = new Overlay({
            element: popupRef.current,
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
            offset: [10, 0],
            positioning: 'center-left',
        });
        map.addOverlay(overlay);

        const vectorSource = new VectorSource();
        const vectorLayer = new VectorLayer({
            source: vectorSource,
        });
        map.addLayer(vectorLayer);

        const markers = {
            1: '/uploads/plan/create.png',
            2: '/uploads/plan/send.png',
            3: '/uploads/plan/sign.png',
            5: '/uploads/plan/call.png',
            4: '/uploads/plan/reject.png',
            6: '/uploads/plan/accepted.png',
            'gip': '/uploads/plan/gip.png',
        };

        if (gipOrders) console.log(`\n gipOrders`, gipOrders);

        if (orders && orders.length > 0) {

            orders?.forEach(order => {
                const iconFeature = new Feature({
                    geometry: new Point([order.coordinate_x, order.coordinate_y]),
                    name: `Marker ${order.id}`,
                    order: order,
                });

                const iconStyle = new Style({
                    image: new Icon({
                        src: markers[order.history[order.history.length - 1].status],
                        height: (qualityControlOrder && qualityControlOrder.id === order.id) ? 55 : 32,
                        anchor: [0.5, 1],
                    }),
                });
                iconFeature.setStyle(iconStyle);

                vectorSource.addFeature(iconFeature);
            });
        }
        if (gipOrders && gipOrders.length > 0) {
            gipOrders.forEach(order => {
                const iconFeature = new Feature({
                    geometry: new Point([order.coordinate_x, order.coordinate_y]),
                    name: `Marker ${order.id}`,
                    gipOrder: order,
                });

                const iconStyle = new Style({
                    image: new Icon({
                        src: markers.gip,
                        height: (qualityControlOrder && qualityControlOrder.id === order.id) ? 55 : 32,
                        anchor: [0.5, 1],
                    }),
                });
                iconFeature.setStyle(iconStyle);

                vectorSource.addFeature(iconFeature);
            });
        }
        if (subcompanyOrder) {

            const iconFeature = new Feature({
                geometry: new Point([subcompanyOrder.coordinate_x, subcompanyOrder.coordinate_y]),
                name: `Marker ${subcompanyOrder.id}`,
                order: subcompanyOrder,
            });

            const iconStyle = new Style({
                image: new Icon({
                    src: markers[subcompanyOrder.history[subcompanyOrder.history.length - 1].status],
                    height: 32,
                    anchor: [0.5, 1],
                }),
            });
            iconFeature.setStyle(iconStyle);

            vectorSource.addFeature(iconFeature);
        }

        map.on('pointermove', function (e) {
            const pixel = map.getEventPixel(e.originalEvent);
            const hit = map.hasFeatureAtPixel(pixel);
            map.getTarget().style.cursor = hit ? 'pointer' : '';
        });

        map.on('click', function (evt) {

            if (isCreateOrder) {
                const coordinate = map.getEventCoordinate(evt.originalEvent);
                setSelectedOrder(null);
                overlay.setPosition(coordinate);
                setCreateOrder(coordinate);
                return;
            } else if (isCreateGipOrder) {
                const coordinate = map.getEventCoordinate(evt.originalEvent);
                overlay.setPosition(coordinate);
                setSelectedGipOrder(null)
                setCreateGipOrder(coordinate);
                return;
            } else if (isMoveGipOrder || isMoveOrder) {
                setNewCoordinate(map.getEventCoordinate(evt.originalEvent));
            }

            const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
                return feature;
            });

            if (!feature) {
                overlay.setPosition(undefined);
                return;
            }

            const order = feature.get('order');
            if (order) {
                setSelectedOrder(order);
                const coordinate = feature.getGeometry().getCoordinates();
                overlay.setPosition(coordinate);
                const mapView = map.getView();

                mapView.animate({
                    center: coordinate,
                    duration: 1000,
                    easing: olEasing.easeOut
                });
            }

            const gipOrder = feature.get('gipOrder');
            if (gipOrder) {
                setCreateGipOrder(null);
                setSelectedGipOrder(gipOrder);
                const coordinate = feature.getGeometry().getCoordinates();
                overlay.setPosition(coordinate);
                const mapView = map.getView();

                mapView.animate({
                    center: coordinate,
                    duration: 1000,
                    easing: olEasing.easeOut
                });
            }
        });

        return () => map.setTarget(undefined);
    }, [imageUrl, orders, imageSize, subcompanyOrder, qualityControlOrder, isCreateOrder, isCreateGipOrder, gipOrders, isMoveGipOrder, isMoveOrder, setNewCoordinate]);

    // console.log(`\n subcompanyOrder`, subcompanyOrder);
    // if (gipOrders) console.log(`\n gipOrders`, gipOrders);
    // if (selectedGipOrder) console.log(`\n selectedGipOrder`, selectedGipOrder);

    return (
        <div ref={mapRef} className={"plan"}>
            <div ref={popupRef}>
                {selectedOrder && <div className={popupStyles.popup}>
                    <header>
                        <h2>Предписание</h2>
                        <div className={popupStyles.close} onClick={handleClosePopup}><ClosePopup/></div>
                    </header>
                    <div className={popupStyles.content}>
                        <OrderInfo
                            order={selectedOrder}
                            fileServer={fileServer}
                            subcompanies={subcompanies}
                            sendOrder={sendOrder}
                            signOrder={signOrder}
                            callToOrder={callToOrder}
                            rejectOrder={rejectOrder}
                            acceptOrder={acceptOrder}
                            moveOrderHandler={moveOrderHandler}
                            editOrder={editOrder}
                            handleClosePopup={handleClosePopup}
                            allIdForQueries={allIdForQueries}
                            setOrders={setOrders}
                        />
                    </div>
                </div>}
                {createOrder && <div className={popupStyles.popup}>
                    <header>
                        <h2>Создать предписание</h2>
                        <div className={popupStyles.close} onClick={handleClosePopup}><ClosePopup/></div>
                    </header>
                    <div className={popupStyles.content}>
                        <CreateOrder
                            createOrder={createOrder.map(number => Math.round(number))}
                            subcompanies={subcompanies}
                            createOrderHandler={createOrderHandler}
                            // tenders={tenders}
                        />
                    </div>
                </div>}
                {selectedGipOrder && <div className={popupStyles.popup}>
                    <header>
                        <h2>Задача ГИП</h2>
                        <div className={popupStyles.close} onClick={handleClosePopup}><ClosePopup/></div>
                    </header>
                    <div className={popupStyles.content}>
                        <TaskEngineer
                            order={selectedGipOrder}
                            editGipOrderHandler={editGipOrderHandler}
                            moveGipOrderHandler={moveOrderHandler}
                            handleClosePopup={handleClosePopup}
                            setGipOrders={setGipOrders}
                        />
                    </div>
                </div>}
                {createGipOrder && <div className={popupStyles.popup}>
                    <header>
                        <h2>Поставить задачу ГИП</h2>
                        <div className={popupStyles.close} onClick={handleClosePopup}><ClosePopup/></div>
                    </header>
                    <div className={popupStyles.content}>
                        <TaskEngineer
                            createOrder={createGipOrder.map(number => Math.round(number))}
                            sendGipOrderHandler={sendGipOrderHandler}
                            setGipOrders={setGipOrders}
                        />
                    </div>
                </div>}
            </div>
        </div>
    );
};