import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../../store/user/user-context";
import {getFinancialPlan, putFinancialPlan, saveFinancialPlan} from "../../SendRequests/FinancialPlanData";
import UploadFinancialPlan from "./UploadFinancialPlan";
import ViewFinancialPlan from "./ViewFinancialPlan";
import {Alert, Box, Button, Snackbar} from "@mui/material";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import RestorePageOutlinedIcon from '@mui/icons-material/RestorePageOutlined';
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import UserSection from "../../UI/Sections/UserSection";

const FinancialSchedule = () => {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '2') navigate('/work/');
    const userSettings = useContext(UserContext);
    const company_id = +window.localStorage.getItem('company');
    const project_id = +window.localStorage.getItem('project');
    const [answers, setAnswers] = useState(null);

    const [financialPlan, setFinancialPlan] = useState(null);
    const [uploadedFinancialPlan, setUploadedFinancialPlan] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false);
    // const [tempFp, setTempFp] = useState(null);

    const [responseMessage, setResponseMessage] = useState({message: "", status: ""})
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    // console.log('\n financialPlan', financialPlan);
    // console.log('\n uploadedFinancialPlan', uploadedFinancialPlan);

    useEffect(() => {

        const getData = async () => {

            try {

                const updateFinancialPlan = await getFinancialPlan(userSettings, company_id, project_id);

                console.log('\n updateFinancialPlan', updateFinancialPlan);

                setFinancialPlan(updateFinancialPlan);
            } catch (error) {
                console.error('getData', error);
            }
        }

        if (userSettings && company_id && project_id) getData();
    }, [userSettings, company_id, project_id]);

    // console.log('\n financialPlan', financialPlan);

    const uploadFinancialPlanHandler = (fp) => {

        // console.log('\n ?', fp, isUpdate);
        const updateFinancialPlan = Object.keys(fp).map(n => {

            const row = fp[n];
            const isUpdateRow = Object.values(isUpdate)?.find(u => u.ssr === row.ssr);
            // console.log('\n ', isUpdateRow?.fact > 0 ? isUpdateRow : row);
            return isUpdateRow?.fact > 0 ? isUpdateRow : row;
        }).reduce((acc, current, index) => {
            acc[index] = current; // Используем индекс в качестве ключа
            return acc;
        }, {});
        // console.log('\n updateFinancialPlan', updateFinancialPlan);
        setUploadedFinancialPlan(updateFinancialPlan);
    }
    const saveFinancialPlanHandler = async () => {

        const savePlan = await saveFinancialPlan(userSettings, company_id, project_id, uploadedFinancialPlan);
        console.log('\n savePlan', savePlan);

        setOpen(true)
        if (savePlan?.success) setResponseMessage({message: "Финансовый план успешно сохранён", status: "success"})
        else setResponseMessage({message: "Произошла ошибка", status: "error"})

        // setTimeout(() => (window.location.reload()), 3000)
    }
    const setUpdateFinancialPlanHandler = () => {

        setIsUpdate(financialPlan);
        setFinancialPlan("not_found");
    }
    const backUpdateFinancialPlanHandler = () => {

        setIsUpdate(false);
        setUploadedFinancialPlan(false);
        setFinancialPlan(isUpdate);
    }
    const updateFinancialPlanHandler = async () => {

        const savePlan = await putFinancialPlan(userSettings, company_id, project_id, uploadedFinancialPlan);
        console.log('\n savePlan', savePlan);

        setOpen(true)
        if (savePlan?.success) setResponseMessage({message: "Финансовый план успешно обновлён", status: "success"})
        else setResponseMessage({message: "Произошла ошибка", status: "error"})

       // setTimeout(() => (window.location.reload()), 3000)
    }

    // if (userSettings) console.log('\n userSettings', userSettings);
    // if (company_id) console.log('\n company_id', company_id);
    // if (project_id) console.log('\n project_id', project_id);
    // if (financialPlan && userSettings && company_id && project_id) console.log('\n financialPlan', financialPlan);
    // console.log('\n uploadedFinancialPlan', uploadedFinancialPlan);

    const viewData = (financialPlan && financialPlan !== "not_found") ?
        Object.values(financialPlan) :
        uploadedFinancialPlan && Object.values(uploadedFinancialPlan)
    ;
    // if (viewData?.length > 0) console.log('\n viewData', viewData);

    return (
        <UserSection>
            <h1>Финансовый план</h1>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert severity={responseMessage.status}>{responseMessage.message}</Alert>
            </Snackbar>
            {answers}
            {(financialPlan === "not_found" && uploadedFinancialPlan) ?
                <Box className={`flex flex-wrap gap-2`}>
                    <Button
                        className={`w-max`}
                        startIcon={<SaveOutlinedIcon/>}
                        variant={`contained`}
                        onClick={isUpdate ? updateFinancialPlanHandler : saveFinancialPlanHandler}
                    >
                        Записать финансовый план
                    </Button>
                    {financialPlan && <Button
                        variant="contained"
                        startIcon={<ArrowBackRoundedIcon/>}
                        onClick={backUpdateFinancialPlanHandler}
                    >Назад</Button>}
                </Box> :
                financialPlan !== "not_found" && <Button
                    className={`w-max`}
                    startIcon={<RestorePageOutlinedIcon/>}
                    variant={`contained`}
                    onClick={setUpdateFinancialPlanHandler}
                >
                    Обновить финансовый план
                </Button>
            }
            {(financialPlan === "not_found" && !uploadedFinancialPlan) &&
                <UploadFinancialPlan
                    uploadFinancialPlanHandler={uploadFinancialPlanHandler}
                    isUpdate={isUpdate}
                    backUpdateFinancialPlanHandler={backUpdateFinancialPlanHandler}
                />
            }
            {viewData?.length > 0 &&
                <ViewFinancialPlan
                    data={viewData}
                />
            }
        </UserSection>
    );
}

export default FinancialSchedule;