import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import TheadSort from "./TheadSort";
import tables from "./table.module.css";
import {Price} from "../Convertors/Price";

export default function DataTable({data, columns}) {
    const [rows, setRows] = useState(data);

    useEffect(() => {
        setRows(data);
    }, [data]);

    const formatCellValue = (row, column) => {
        const value = row[column.tag];
        if (column.tag === 'sum_period' && value > 0) return Price(value);
        if (column.tag === 'name') return value;
        if (!isNaN(column.tag) && new Date(column.tag * 1000) instanceof Date) return value > 0 ? Price(value) : '';
        return value;
    };

    if (!rows?.length) return null;

    return (
        <table className={`${tables.table} overflow-x-scroll border-spacing-0`}>
            {/*<TheadSort*/}
            {/*    array={rows}*/}
            {/*    names={columns}*/}
            {/*    setArray={setRows}*/}
            {/*    sortable={false}*/}
            {/*/>*/}
            <thead className="sticky top-0 z-[100] bg-[#0f1937] text-white rounded-t-lg h-[80px]">
            <tr>
                {columns.map((n, index) => {
                    return (
                        <th
                            className={`
                            ${index === 0 ? "sticky left-0 z-50 bg-[#0F1937]" : ""}
                            ${index === 1 ? "sticky left-[67px] z-50 bg-[#0F1937]" : ""}
                            `}
                            key={n.tag ? n.tag : index}
                        >
                            {n.name}
                        </th>
                    )
                })}
            </tr>
            </thead>
            <tbody>
            {rows.map((row, index) => (
                <tr key={index} className={row.classGroup ? tables[row.classGroup] : ""}>
                    {columns.map((column, num) => (
                        <td
                            key={num}
                            className={`
                                    ${(column.tag === 'sum_period' || !isNaN(row[column.tag])) ? tables.price : ''} 
                                    ${column.tag === 'name' ? tables.rowName : ''}
                                    ${column.tag === 'ssr' ? 'text-center' : ''}
                                    ${num === 0 ? "sticky left-0" : ""}
                                    ${num === 1 ? "sticky left-[67px]" : ""}
                                    ${row.classGroup ? tables[row.classGroup] : "bg-white"}
                              
                                `}
                        >
                            {column.tag === 'name' ?
                                <Box className={`w-[400px]`}>{formatCellValue(row, column)}</Box> :
                                formatCellValue(row, column)
                            }
                        </td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    );
}