export const getFirstDateWithCurrentYear = (work) => {
    const allDates = work.work_log.filter(l => l.certificates_completed_work.length === 0).map(l => ({
        date: l.date,
        value: l.value
    }));

    if (allDates.length === 0) {
        return "-";
    }
    const currentYear = String(new Date().getFullYear());
    const currentYearDates = allDates.filter(date => date.date.startsWith(currentYear));

    let startYearTotalCost = 0;
    const costByUnit = (Number(work.unit_cost_smr) + Number(work.unit_cost_materials));
    currentYearDates.forEach(date => startYearTotalCost += (+date.value));
    startYearTotalCost = parseFloat((startYearTotalCost * costByUnit).toFixed(2));

    return startYearTotalCost;
}

export function convertDateString(date) {
    const [year, month, day] = date.split('-');
    return `${day}.${month}.${year}`;
}
