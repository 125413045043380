import React, {useContext, useEffect, useState} from "react";
import styles from "./User.module.css";
import UserContext from "../../../store/user/user-context";
import {ReactComponent as LogoutIcon} from "../../UI/Icons/ControlPanel/logout.svg";
import UserSelect from "./UserSelect";
import axios from "axios";
import Btn from "../../UI/Buttons/Btn";
import {Link} from "react-router-dom";
import RouteScheme from "./RouteScheme.json";
import {getTender} from "../../UI/GetInfo/getSubcompanies";
import {Home} from "@mui/icons-material";

const UserPanel = (props) => {

    const userContext = useContext(UserContext);
    const [roles, setRoles] = useState();
    const [projects, setProjects] = useState();
    const [wonAccessedTenders, setWonAccessedTenders] = useState();
    const storageCompany = +window.localStorage.getItem('company');

    const changeUserState = (type, id) => {
        console.log(type, id)
        window.localStorage.setItem(type, id);

        if (type === 'company') {

            const currentCompany = userContext.user_companies.find(company => company.company === id);

            window.localStorage.setItem('role', currentCompany.roles[0]);
            window.localStorage.setItem('project', currentCompany.projects[0]);

            if (currentCompany.roles[0] !== 6) window.location.replace(RouteScheme.find(r => r.role === currentCompany.roles[0]).routes[0].route);
            else window.location.replace('/work/');
        } else if (type === 'role') {

            // console.log(`\n `, RouteScheme.find(r => r.role === id),RouteScheme.find(r => r.role === id).routes[0].route);

            window.location.replace(RouteScheme.find(r => r.role === id).routes[0].route);
        } else if (type === 'tender') {
            console.log(id)
            console.log(RouteScheme.find(r => r.role === 6).routes)
            window.location.reload();
        } else window.location.reload();
    }
    const leaveAccount = () => {
        window.localStorage.removeItem("company")
        window.localStorage.removeItem("role")
        window.localStorage.removeItem("project")
        window.location.replace("/");
    }

    useEffect(() => {

        const selectedCompany = userContext.user_companies.filter(company => company.company === storageCompany)[0];

        if (selectedCompany) {

            const selectedRoles = [];

            selectedCompany.roles.forEach(role => {

                const resultRoles = {
                    id: role,
                    name: userContext.template_roles[role]
                };

                selectedRoles.push(resultRoles);
            });

            const getProjects = async (company_id) => {

                try {

                    const updateProjects = await axios.get(
                        `${userContext.api}/company/${company_id}/projects/projects/`,
                        userContext.headers.get
                    );

                    // console.log(`\n updateProjects`, updateProjects.data.data);
                    // FILTER PAUSED PROJECTS
                    const user_id = userContext?.user_info?.id;
                    const allUserCompanies = userContext?.user_info?.user_companies;
                    const userProjects = allUserCompanies?.find(user => user.id === user_id)?.projects
                    const availableProjects = updateProjects.data.data.filter(project => project.action === 1 && userProjects.includes(project.id))
                    const sortedProjects = availableProjects.sort((a, b) => a.id - b.id)
                    // console.log(sortedProjects)
                    setProjects(sortedProjects);
                } catch (err) {
                    console.log(`\n getProjects error`, err.response.data);
                }
            }

            getTender(userContext, storageCompany)
                .then(data => setWonAccessedTenders(data
                    ?.filter(tender => tender.contract.agreement_document)
                    .map(tender => ({id: tender.tender.id, name: tender.tender.name}))
                ))

            if (localStorage.role !== "6") getProjects(selectedCompany.company);

            const allUserCompanies = userContext?.user_info?.user_companies;
            const currentCompanyProjects = allUserCompanies?.find(company => company.company === storageCompany)?.projects

            const availableRoles = currentCompanyProjects.length > 0
                ? selectedRoles
                : [{id: 1, name: 'Администратор'}]

            setRoles(availableRoles);
        }
    }, [userContext.template_roles, userContext, storageCompany]);

    const companiesList = userContext.user_companies
        .sort((a, b) => a.id - b.id)
        .map(company => ({
                ...company,
                name: company.roles.includes(6) ? company.name + " (Подрядчик)" : company.name + " (Инвестор)"
            })
        )
        .filter(company => !company.is_blocked);


    // console.log(`\n companiesList`, companiesList);
    // console.log(wonAccessedTenders)
    // console.log(roles)
    return (
        <React.Fragment>
            <div className={`${styles.member} ${!props.state ? styles.collapsed : ''}`}>
                <div className={styles['user-name']}>{userContext.short_name}</div>
                <div className={styles['user-mail']}>{userContext.email}</div>
                <div className={`${!props.state ? '' : 'flex'}`}>
                    <Link title={`Вернуться к выбору роли и компании`} to={"/work/"}>
                        <Btn
                            color={props.state ? `white` : 'button'}
                            className={`${!props.state ? 'mx-auto fill-white' : 'mr-2'}`}
                            icononly={true}
                        >
                            <Home/>
                        </Btn>
                    </Link>
                    <Btn
                        color={props.state ? `white` : 'button'}
                        title={`Вернуться на главную страницу`}
                        onClick={leaveAccount}
                        icononly={!props.state}
                        className={`grow text-center mx-auto`}
                    >
                        {!props.state ? <LogoutIcon/> : 'Вернуться'}
                    </Btn>
                </div>
            </div>
            <UserSelect
                type='company'
                options={companiesList}
                defaultValue={+window.localStorage.getItem('company')}
                state={props.state}
                shownList={props.shownList}
                showListHandler={props.showListHandler}
                onChageUserState={changeUserState}
            />
            {(roles && roles.length > 0) ?
                <UserSelect
                    type='role'
                    options={roles}
                    defaultValue={+window.localStorage.getItem('role')}
                    state={props.state}
                    shownList={props.shownList}
                    showListHandler={props.showListHandler}
                    onChageUserState={changeUserState}
                /> : ''
            }
            {(wonAccessedTenders && wonAccessedTenders.length > 0) ?
                <UserSelect
                    type='tender'
                    options={wonAccessedTenders}
                    defaultValue={+window.localStorage.getItem('tender')}
                    state={props.state}
                    shownList={props.shownList}
                    showListHandler={props.showListHandler}
                    onChageUserState={changeUserState}
                /> : ''
            }
            {(projects && projects.length > 0 && +window.localStorage.getItem('role') !== 1) ?
                <UserSelect
                    type='project'
                    options={projects}
                    defaultValue={+window.localStorage.getItem('project')}
                    state={props.state}
                    shownList={props.shownList}
                    showListHandler={props.showListHandler}
                    onChageUserState={changeUserState}
                /> : ''
            }
        </React.Fragment>
    );
}

export default UserPanel;