import React, {useContext, useEffect, useState} from "react";
import styles from "../dash.module.css";
import UserSection from "../../UI/Sections/UserSection";
import Grids from "../../UI/Grids/Grids";
import {Pasport} from "../../UI/Convertors/Pasport";
import {Link, useNavigate} from "react-router-dom";
import Btn from "../../UI/Buttons/Btn";
import Popup from "../../UI/Popup/Popup";
import axios from "axios";
import UserContext from "../../../store/user/user-context";
import {ReactComponent as Employee} from "../../UI/Icons/employee.svg";
import WatchDocs from "./WatchDocs";
import Button from "../../UI/Buttons/Button";
import {Alert, Snackbar, Tooltip} from "@mui/material";
import {PrintPhone} from "../../UI/Convertors/PrintPhone";
import Warnings from "../../UI/Sections/Warnings";

const CompanyRepresentatives = () => {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '2') navigate('/work/');

    const [popupShow, setPopupShow] = useState(false);

    const userSettings = useContext(UserContext);
    const company_id = window.localStorage.getItem('company');
    const project_id = window.localStorage.getItem('project');

    const [isAlreadyCopied, setIsAlreadyCopied] = useState(false);
    const [errorNames, setErrorNames] = useState("")

    const [answers, setAnswers] = useState(null);

    const names = {
        "type": {
            "1": "Физическое лицо",
            "2": "Юридическое лицо",
            "3": "ИП",
        },
        "person": [
            {
                id: 1,
                label: "Застройщик",
                name: "developer",
            },
            {
                id: 2,
                label: "Технический заказчик",
                name: "technicalCustomer",
            },
            {
                id: 3,
                label: `Технический заказчик, представитель по вопросам строительного контроля`,
                name: "technicalCustomerRepresentative",
            },
            {
                id: 4,
                label: "Генеральный подрядчик",
                name: "generalContractor",
            },
            {
                id: 5,
                label: "Генеральный проектировщик",
                name: "generalDesigner",
            }
        ],
        "fields": {
            "persons": [
                "name",
                "type",
                "person",
                "passport_number",
                "registration_certificate",
                "ogrn",
                "inn",
                "legal_address",
                "postal_address",
                "scanned_passport_page_2",
                "scanned_passport_page_3",
                "scanned_passport_page_4",
                "scanned_passport_page_5"
            ]
        },
        "docs": {
            "1": "Приказ",
            "2": "Доверенность",
            "3": "НРС/НОПРИЗ",
            "4": "СРО",
            "5": "Лицензия",
        }
    };
    const [persons, setPersons] = useState([]);

    useEffect(() => {

        const options = {
            headers: {
                Authorization: userSettings.token,
                'accept': 'application/json'
            }
        }


        axios.get(
            `${userSettings.api}/company/${company_id}/projects/${project_id}/persons/`,
            options
        )
            .then(response => {
                if (response.data) {
                    setPersons(response.data);

                    // console.log(`\n `, response.data);
                }
            })
            .catch(error => console.log(`\n error`, (error.code === 'ERR_BAD_REQUEST') ? 'ERR_BAD_REQUEST' : error.data));
    }, [company_id, project_id, userSettings]);

    const addPersonHandler = (person, label, person_id) => {

        const addPerson = async (data) => {

            setPopupShow(false);

            try {

                data['person'] = +person;
                data['type'] = +data.type;

                const options = {headers: userSettings.headers.postForm};

                const formData = new FormData();
                Object.keys(data).forEach(k => {
                    if (k.includes('scanned_passport')) formData.append(k, data[k][0]);
                    else formData.append(k, data[k]);
                });
                names.fields.persons.forEach(n => {

                    if (!Object.keys(data).includes(n)) formData.append(n, '');
                });


                if (person_id) {

                    const response = await axios.put(
                        `${userSettings.api}/company/${company_id}/projects/${project_id}/persons/${person_id}/`,
                        formData,
                        options
                    )
                    console.log(`\n response`, response);

                    if (response.data) {

                        const getData = response.data;
                        if (!response.data.representatives) getData['representatives'] = [];

                        const indexToUpdate = persons.findIndex(person => person.id === response.data.id);
                        if (indexToUpdate !== -1) {

                            setPersons(prevPersons => {
                                const updatedPersons = [...prevPersons];
                                updatedPersons[indexToUpdate] = getData;
                                return updatedPersons;
                            });
                        }
                    }
                } else {

                    const response = await axios.post(
                        `${userSettings.api}/company/${company_id}/projects/${project_id}/persons/`,
                        formData,
                        options
                    )
                    console.log(`\n response`, response);
                    if (response.data) {

                        const getData = response.data;
                        if (!response.data.representatives) getData['representatives'] = [];

                        setPersons(prevPersons => [...prevPersons, getData]);
                    }
                }
            } catch (error) {
                setAnswers(<Warnings>
                    Ошибка создания лица - {error.response.data.errors ?
                    error.response.data.errors.map((e, id) => <p key={id}>{e.detail} - {e.attr}</p>)
                    :
                    error.response.status
                }
                </Warnings>)
                console.log(`\n addPersonHandler error`, error.response);
            }
        };

        setPopupShow(<Popup
            title={label}
            representatives={true}
            onSubmit={addPerson}
            onCloseModal={() => setPopupShow(false)}
        />);
    };
    const editPersonHandler = (person, label, personData) => {

        const editPerson = (data) => {

            data['type'] = personData.type;
            data['person'] = personData.person;

            const formData = new FormData();
            Object.keys(data).forEach(k => {
                if (k.includes('scanned_passport') && data[k][0]) formData.append(k, data[k][0]);
                else if (!k.includes('scanned_passport')) formData.append(k, data[k]);
            });

            const options = {headers: userSettings.headers.postForm};

            names.fields.persons.forEach(n => {

                if (!Object.keys(data).includes(n)) formData.append(n, '');
            });

            axios.put(
                `${userSettings.api}/company/${company_id}/projects/${project_id}/persons/${personData.id}/`,
                formData,
                options
            )
                .then(response => {
                    if (response.data) {
                        const indexToUpdate = persons.findIndex(person => person.id === response.data.id);
                        if (indexToUpdate !== -1) {

                            const getData = response.data;

                            if (!response.data.representatives) getData['representatives'] = [];

                            setPersons(prevPersons => {
                                const updatedPersons = [...prevPersons];
                                updatedPersons[indexToUpdate] = response.data;
                                return updatedPersons;
                            });
                        }
                        setPopupShow(false);
                    }
                })
                .catch(error => console.log(`\n error`, (error.code === 'ERR_BAD_REQUEST') ? 'ERR_BAD_REQUEST' : error.data));
        }

        setPopupShow(<Popup
            title={label}
            edit_representatives={personData}
            onSubmit={editPerson}
            addNewPerson={() => {
                setPopupShow(false);
                addPersonHandler(person, label, personData.id);
            }}
            onCloseModal={() => setPopupShow(false)}
        />);
    };

    const [person_id, setPerson_id] = useState();
    const [isCheckedDocs, setIsCheckedDocs] = useState(false);
    const addRepresentativesHandler = () => {
        const addRepresentatives = async (data) => {

            setPopupShow(false);

            try {

                data['phone'] = data.phone.replace(/ /g, '');

                const formData = new FormData();
                Object.keys(data).forEach(k => {
                    if (k.includes('scanned_passport')) formData.append(k, data[k][0]);
                    else formData.append(k, data[k]);
                });
                formData.append('is_active', true);

                const response = await axios.post(
                    `${userSettings.api}/company/${company_id}/projects/${project_id}/persons/${person_id}/representative/`,
                    formData,
                    {headers: userSettings.headers.postForm}
                )

                const representative = response.data;
                representative['docs'] = [];
                representative['person'] = person_id;
                setPersons(prevPersons => {
                    return prevPersons.map(person => {
                        if (person.id === person_id) {
                            const updatedRepresentatives = [...person.representatives, representative];
                            return {...person, representatives: updatedRepresentatives};
                        }
                        return person;
                    });
                });
            } catch (error) {
                setAnswers(<Warnings>
                    Ошибка создания представителя - {error.response.data.errors ?
                    error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                    :
                    error.response.status
                }
                </Warnings>)
                console.log(`\n addRepresentatives error`, error.response);
            }
        };

        setPopupShow(<Popup
            title="Добавить представителя"
            form={{
                fields: [
                    {
                        name: "name",
                        type: "text",
                        label: "Ф.И.О. представителя",
                    },
                    {
                        name: "position",
                        type: "text",
                        label: "Должность представителя",
                    },
                    {
                        name: "phone",
                        type: "tel",
                        label: "Телефон представителя",
                    },
                ],
                submit: 'Добавить'
            }}
            onSubmit={addRepresentatives}
            onCloseModal={() => setPopupShow(false)}
        />);
    };
    const editRepresentatives = (id, data) => {

        const formData = new FormData();
        Object.keys(data).forEach(k => {
            if (k.includes('file')) formData.append(k, data[k][0]);
            else formData.append(k, data[k]);
        });
        if (!Object.keys(data).includes('is_active')) formData.append('is_active', true);

        const options = {headers: userSettings.headers.postForm};

        axios
            .put(
                `${userSettings.api}/company/${company_id}/projects/${project_id}/persons/${person_id}/representative/${id}/`,
                formData,
                options
            )
            .then((response) => {
                if (response.data) {
                    const updatedRepresentativeData = {
                        name: response.data.name,
                        position: response.data.position,
                        phone: response.data.phone,
                        is_active: response.data.is_active
                    };

                    setPersons(prevPersons => {
                        return prevPersons.map(person => {
                            if (person.id === person_id) {
                                const updatedRepresentatives = person.representatives.map(rep => {
                                    if (rep.id === id) {
                                        return {...rep, ...updatedRepresentativeData};
                                    }
                                    return rep;
                                });
                                return {...person, representatives: updatedRepresentatives};
                            }
                            return person;
                        });
                    });

                    setPopupShow(false);
                }
            })
            .catch(error => console.log('\n error', error));
    };
    const editRepresentativesHandler = (representative) => {

        const editRepresentativesTransfer = (data) => {

            data['phone'] = data.phone.replace(/ /g, '');

            editRepresentatives(representative.id, data, true);
        }

        setPopupShow(<Popup
            title="Редактировать представителя"
            form={{
                fields: [
                    {
                        name: "name",
                        type: "text",
                        label: "Ф.И.О. представителя",
                        defaultValue: representative.name
                    },
                    {
                        name: "position",
                        type: "text",
                        label: "Должность представителя",
                        defaultValue: representative.position
                    },
                    {
                        name: "phone",
                        type: "tel",
                        label: "Телефон представителя",
                        defaultValue: representative.phone
                    },
                ],
                submit: 'Сохранить'
            }}
            onSubmit={editRepresentativesTransfer}
            onCloseModal={() => setPopupShow(false)}
        />);
    };
    const removeRepresentativesHandler = (representative) => {

        setPopupShow(<Popup
            title="Удалить представителя"
            content={(
                <React.Fragment>
                    <div>{representative.name}</div>
                    <Grids cols={2}>
                        <Button onClick={() => editRepresentatives(representative.id, {
                            "is_active": false
                        })}>Да</Button>
                        <Button onClick={() => setPopupShow(false)}>Нет</Button>
                    </Grids>
                </React.Fragment>
            )}
            onCloseModal={() => setPopupShow(false)}
        />);
    };
    const returnRepresentativesHandler = () => {

        const represtativesList = persons.filter(p => p.id === person_id)[0].representatives.filter(r => !r.is_active);

        setPopupShow(<Popup
            title="Удалить представителя"
            content={(
                <Grids cols={2}>
                    {represtativesList.map(r => (
                        <React.Fragment key={r.id}>
                            <div>{r.name}</div>
                            <Button onClick={() => editRepresentatives(r.id, {
                                "is_active": true
                            })}>Восстановить</Button>
                        </React.Fragment>
                    ))}
                </Grids>
            )}
            onCloseModal={() => setPopupShow(false)}
        />);
    };
    const addDocsHandler = (id, docs) => {

        const addDocs = (data) => {

            const options = {headers: userSettings.headers.postForm};

            const formData = new FormData();
            Object.keys(data).forEach(k => {
                if (k.includes('file')) formData.append(k, data[k][0]);
                else formData.append(k, data[k]);
            });

            console.log(`\n formData`, formData);

            axios
                .post(
                    `${userSettings.api}/company/${company_id}/projects/${project_id}/persons/${person_id}/representative/${id}/docs/`,
                    formData,
                    options
                )
                .then((response) => {
                    if (response.data) {

                        const updatedPersons = persons.map((person) => {
                            if (person.id === person_id) {
                                const updatedRepresentatives = person.representatives.map((rep) => {
                                    if (rep.id === id) {
                                        const newDoc = {
                                            id: response.data.id,
                                            type: response.data.type,
                                            number: response.data.number,
                                            date: response.data.date,
                                            organization_issued_document: response.data.organization_issued_document,
                                            file: response.data.file,
                                        };
                                        return {
                                            ...rep,
                                            docs: [...rep.docs, newDoc],
                                        };
                                    }
                                    return rep;
                                });
                                return {
                                    ...person,
                                    representatives: updatedRepresentatives,
                                };
                            }
                            return person;
                        });
                        setPersons(updatedPersons);
                        setPopupShow(false);
                    }
                })
                .catch(error => console.log(`\n error`, (error.code === 'ERR_BAD_REQUEST') ? 'ERR_BAD_REQUEST' : error.data));
        }

        const options = Object.keys(names.docs).map(id => {
            return {
                id: id,
                name: names.docs[id]
            };
        }).filter(option => {
            const typeInDocs = docs.some(doc => doc.type === parseInt(option.id, 10));
            return !typeInDocs;
        })
        console.log(options)
        setPopupShow(<Popup
            title="Добавить документ представителя"
            form={{
                fields: [
                    {
                        name: "type",
                        type: "select",
                        label: "Тип документа",
                        options: options
                    },
                    {
                        name: "number",
                        type: "text",
                        label: "Номер документа",
                    },
                    {
                        name: "date",
                        type: "date",
                        label: "Дата выдачи документа",
                        max: new Date().toISOString().split('T')[0],
                    },
                    ...((options.id === "1") ? [{
                        name: "organization_issued_document",
                        type: "text",
                        label: "Организация выдавшая документ",
                    }] : []),
                    {
                        name: "file",
                        type: "file",
                        label: "Документ",
                    },
                ],
                submit: 'Добавить'
            }}
            onSubmit={addDocs}
            onCloseModal={() => setPopupShow(false)}
        />);
    };
    const editDocsHandler = (id, doc) => {

        const editDocs = (data) => {

            data.type = doc.type;

            const options = {
                headers: {
                    Authorization: userSettings.token,
                    'accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            };

            const formData = new FormData();
            Object.keys(data).forEach(k => {
                if (k.includes('file')) formData.append(k, data[k][0]);
                else formData.append(k, data[k]);
            });

            axios
                .put(
                    `${userSettings.api}/company/${company_id}/projects/${project_id}/persons/${person_id}/representative/${id}/docs/${doc.id}`,
                    formData,
                    options
                )
                .then((response) => {
                    if (response.data) {

                        const updatedDocument = {...response.data};

                        setPersons(prevPersons => {
                            const updatedPersons = prevPersons.map(person => {
                                if (person.id === person_id) {
                                    const updatedRepresentatives = person.representatives.map(rep => {
                                        if (rep.id === id) {
                                            const updatedDocs = rep.docs.map(document => {
                                                if (document.id === doc.id) {
                                                    return updatedDocument;
                                                }
                                                return document;
                                            });
                                            return {...rep, docs: updatedDocs};
                                        }
                                        return rep;
                                    });
                                    return {...person, representatives: updatedRepresentatives};
                                }
                                return person;
                            });
                            return updatedPersons;
                        });

                        setPopupShow(false);
                    }
                })
                .catch(error => console.log(`\n error`, (error.code === 'ERR_BAD_REQUEST') ? 'ERR_BAD_REQUEST' : error.data));

        }

        setPopupShow(<Popup
            title={`Редактировать документ представителя ${names.docs[doc.type]}`}
            form={{
                fields: [
                    {
                        name: "number",
                        type: "text",
                        label: "Номер документа",
                        defaultValue: doc.number
                    },
                    {
                        name: "date",
                        type: "date",
                        label: "Дата выдачи документа",
                        defaultValue: doc.date
                    },
                    {
                        name: "organization_issued_document",
                        type: "text",
                        label: "Организация выдавшая документ",
                        defaultValue: doc.organization_issued_document
                    },
                    {
                        name: "file",
                        type: "file",
                        label: "Документ",
                    },
                ],
                submit: 'Сохранить'
            }}
            onSubmit={editDocs}
            onCloseModal={() => setPopupShow(false)}
        />);
    };

    const copyRepresentativeHandler = (person_id, representative) => {
        const getPersonById = (id) => persons.find(p => p.id === +id)?.name

        const copyRepresentative = async (data) => {
            delete data.name;

            console.log(representative);
            console.log(data)
            const requests = Object.keys(data)
                .filter(id => data[id]) // Only consider valid ids
                .map(id =>
                    axios.post(
                        `${userSettings.api}/company/${company_id}/projects/${project_id}/persons/${id}/representative/`,
                        representative,
                        {headers: userSettings.headers.postForm}
                    ).then(response => ({
                        status: 'fulfilled',
                        id,
                        data: response.data
                    })) // On success, return the id and data
                        .catch(error => ({
                            status: 'rejected',
                            id,
                            error: error.response ? error.response.data : error.message
                        })) // On failure, return the id and error
                );

            const responses = await Promise.allSettled(requests);
            const failedResponses = responses.filter(r => r?.value?.status === 'rejected');
            const failedIds = failedResponses.map(r => r?.value?.id)
            // console.log(failedIds)
            // console.log(persons)
            // console.log(getPersonById(failedIds[0]))
            const failedPersons = failedIds.reduce((acc, id) => getPersonById(id) + ", " + acc, "");
            console.log(failedPersons)

            if (failedPersons) {
                setIsAlreadyCopied(true)
                setErrorNames(failedPersons)
            }

            setTimeout(() => (window.location.reload()), 3000)
        }

        setPopupShow(<Popup
            title={`Копировать представителя`}
            form={{
                type: "checkbox",
                fields: persons.filter(p => p.id !== person_id).map(r => {
                    console.log(r)
                    return ({
                        "type": 'checkbox',
                        "name": r.id,
                        "label": r.name,
                        "checked": 'false',
                    })
                }),
                "submit": "Скопировать представителя"
            }}
            onSubmit={copyRepresentative}
            onCloseModal={() => setPopupShow(false)}
        />);
    };

    const addCompanyDocsHandler = () => {
        const addDocs = async (data) => {

            setPopupShow(false);

            try {
                console.log(data)

                const formData = new FormData();
                formData.append("type", 1);
                formData.append("number", data.number);
                formData.append("date", data.date);
                formData.append("organization_issued_document", data.organization_issued_document);
                formData.append("file", data.file[0]);

                const response = await axios.post(
                    `${userSettings.api}/company/${company_id}/projects/${project_id}/persons/${person_id}/docs/`,
                    formData,
                    {headers: userSettings.headers.postForm}
                )

                const doc = response.data;

                setPersons(prevPersons => {
                    return prevPersons.map(person => {
                        if (person.id === person_id) {
                            const updatedDocs = [...person.docs, doc];
                            return {...person, docs: updatedDocs};
                        }
                        return person;
                    });
                });
            } catch (error) {
                setAnswers(<Warnings>
                    Ошибка создания представителя - {error.response.data.errors ?
                    error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                    :
                    error.response.status
                }
                </Warnings>)
                console.log(`\n addRepresentatives error`, error.response);
            }
        };

        setPopupShow(<Popup
            title="Добавить CРО"
            form={{
                fields: [
                    {
                        name: "number",
                        type: "text",
                        label: "Номер документа",
                    },
                    {
                        name: "organization_issued_document",
                        type: "text",
                        label: "Организация, выдавшая документ",
                    },
                    {
                        name: "date",
                        type: "date",
                        label: "Дата выдачи документа",
                    },
                    {
                        name: "file",
                        type: "file",
                        label: "Документ",
                    },
                ],
                submit: 'Добавить'
            }}
            onSubmit={addDocs}
            onCloseModal={() => setPopupShow(false)}
        />);
    };

    const editCompanyDocsHandler = (doc) => {
        const editDocs = async (data) => {
            setPopupShow(false);

            try {
                const formData = new FormData();
                formData.append("type", 1);
                formData.append("number", data.number);
                formData.append("date", data.date);
                formData.append("organization_issued_document", data.organization_issued_document);
                formData.append("file", data.file[0]);

                const response = await axios.put(
                    `${userSettings.api}/company/${company_id}/projects/${project_id}/persons/${person_id}/docs/${doc.id}/`,
                    formData,
                    {headers: userSettings.headers.postForm}
                )

                const document = response.data;

                setPersons(prevPersons => {
                    return prevPersons.map(person => {
                        if (person.id === person_id) {
                            const updatedDocs = person.docs.map(curDoc => {
                                if (curDoc.id === doc.id) return document;
                                else return curDoc
                            });
                            return {...person, docs: updatedDocs};
                        }
                        return person;
                    });
                });
            } catch (error) {
                setAnswers(<Warnings>
                    Ошибка создания представителя
                </Warnings>)
                console.log(`\n addRepresentatives error`, error.response);
            }
        };

        setPopupShow(<Popup
            title="Изменить CРО"
            form={{
                fields: [
                    {
                        name: "number",
                        type: "text",
                        label: "Номер документа",
                        defaultValue: doc.number,
                    },
                    {
                        name: "organization_issued_document",
                        type: "text",
                        label: "Организация, выдавшая документ",
                        defaultValue: doc.organization_issued_document,
                    },
                    {
                        name: "date",
                        type: "date",
                        label: "Дата выдачи документа",
                        defaultValue: doc.date,
                    },
                    {
                        name: "file",
                        type: "file",
                        label: "Документ",
                    },
                ],
                submit: 'Редактировать'
            }}
            onSubmit={editDocs}
            onCloseModal={() => setPopupShow(false)}
        />);
    };

    const deleteCompanyDocsHandler = (document_id) => {
        axios.delete(
            `${userSettings.api}/company/${company_id}/projects/${project_id}/persons/${person_id}/docs/${document_id}/`,
            {headers: {Authorization: userSettings.token}}
        ).then(response => {
            setPersons(prevPersons => {
                return prevPersons.map(person => {
                    if (person.id === person_id) {
                        const updatedDocs = person.docs.filter(curDoc => curDoc.id !== document_id)
                        console.log(updatedDocs)
                        console.log({...person, docs: updatedDocs})
                        return {...person, docs: updatedDocs}
                    } else return person
                })
            });
        }).catch(error => {
            console.error(error);
        });
    }

    // console.log(persons)
    return (
        <UserSection>
            {popupShow}
            <h1>Представители компании</h1>
            {answers && answers}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                open={isAlreadyCopied}
                autoHideDuration={3000}
                onClose={() => setIsAlreadyCopied(false)}
            >
                <Alert severity="error">{errorNames} уже содержат этого представителя</Alert>
            </Snackbar>
            {!person_id ?
                <Grids cols={3}>
                    {names.person.map(p => {

                        const getPerson = persons.find(person => person.person === p.id);

                        return (<div key={p.id} className={styles.card}>
                            <div className={styles.label}>{p.label}</div>
                            {getPerson ?
                                <div className={`px-2 flex flex-col h-full`}>
                                    <div className={`flex-1`}>{getPerson.type === 1 || getPerson.type === 2 ?
                                        <React.Fragment>
                                            <div className={styles.personName}>{getPerson.name}</div>
                                            <Grids cols={2}>
                                                {/*<div className={styles.rowName}>Свидетельство о регистрации (номер, дата)</div>*/}
                                                {/*<div>{getPerson.registration_certificate}</div>*/}
                                                <div className={styles.rowName}>ОГРН</div>
                                                <div>{getPerson.ogrn}</div>
                                                <div className={styles.rowName}>ИНН</div>
                                                <div>{getPerson.inn}</div>
                                                <div className={styles.rowName}>Юридический адрес</div>
                                                <div>{getPerson.legal_address}</div>
                                                <div className={styles.rowName}>Почтовый адрес</div>
                                                <div>{getPerson.postal_address}</div>
                                            </Grids>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className={styles.personName}>{getPerson.name}</div>
                                            <Grids cols={2}>
                                                <div className={styles.rowName}>Паспорт</div>
                                                <div>{Pasport(getPerson.passport_number)}</div>
                                                <div className={styles.rowName}>Адрес регистрации</div>
                                                <div>{getPerson.legal_address}</div>
                                            </Grids>
                                            {getPerson.scanned_passport_page_2 &&
                                                <>
                                                    <div className={styles.rowName}>Паспорт 2-5 страницы:</div>
                                                    <div className={`flex`}>
                                                        <Link
                                                            to={`${userSettings.fileServer}${getPerson.scanned_passport_page_2}`}
                                                            target="_blank"
                                                            className={`grid place-content-center p-0.5 mx-1 border border-pult/30 rounded-md overflow-hidden my-0.5`}>
                                                            <img
                                                                src={`${userSettings.fileServer}${getPerson.scanned_passport_page_2}`}
                                                                alt={`Страница паспорта 2`}/>
                                                        </Link>
                                                        <Link
                                                            to={`${userSettings.fileServer}${getPerson.scanned_passport_page_3}`}
                                                            target="_blank"
                                                            className={`grid place-content-center p-0.5 mx-1 border border-pult/30 rounded-md overflow-hidden my-0.5`}>
                                                            <img
                                                                src={`${userSettings.fileServer}${getPerson.scanned_passport_page_3}`}
                                                                alt={`Страница паспорта 3`}/>
                                                        </Link>
                                                        <Link
                                                            to={`${userSettings.fileServer}${getPerson.scanned_passport_page_4}`}
                                                            target="_blank"
                                                            className={`grid place-content-center p-0.5 mx-1 border border-pult/30 rounded-md overflow-hidden my-0.5`}>
                                                            <img
                                                                src={`${userSettings.fileServer}${getPerson.scanned_passport_page_4}`}
                                                                alt={`Страница паспорта 4`}/>
                                                        </Link>
                                                        <Link
                                                            to={`${userSettings.fileServer}${getPerson.scanned_passport_page_5}`}
                                                            target="_blank"
                                                            className={`grid place-content-center p-0.5 mx-1 border border-pult/30 rounded-md overflow-hidden my-0.5`}>
                                                            <img
                                                                src={`${userSettings.fileServer}${getPerson.scanned_passport_page_5}`}
                                                                alt={`Страница паспорта 5`}/>
                                                        </Link>
                                                    </div>
                                                </>
                                            }
                                        </React.Fragment>
                                    }</div>
                                    <div
                                        className={`flex flex-col items-center`}>
                                        <Btn color="button" method="edit"
                                             onClick={() => editPersonHandler(p.id, p.label, getPerson)}>Редактировать
                                            лицо</Btn>
                                        {getPerson.representatives && <Btn color="button" icon={<Employee/>}
                                                                           onClick={() => setPerson_id(getPerson.id)}>Представители {getPerson.representatives.filter(r => r.is_active).length}</Btn>}
                                        {getPerson.docs && <Btn color="button" icon={<Employee/>}
                                                                onClick={() => {
                                                                    setPerson_id(getPerson.id);
                                                                    setIsCheckedDocs(true);
                                                                }}>Документы {getPerson.docs.length}</Btn>}


                                    </div>
                                </div>
                                :
                                <div className={`flex flex-col items-center mx-auto`}>
                                    {/*{(p.id === 3 && persons && persons.find(p => p.person === 2) && !persons.some(p => p.person === 3)) && <Btn method={"clone"} color="button" onClick={cloneTechHandler}>Копировать из "Технический заказчик"</Btn>}*/}
                                    <Btn color="button" method="add" onClick={() => addPersonHandler(p.id, p.label)}>Добавить
                                        лицо</Btn>
                                </div>
                            }
                        </div>);
                    })}</Grids>
                : <>
                    {
                        isCheckedDocs ?
                            <React.Fragment>
                                <h2>Документы {persons.filter(p => p.id === person_id)[0].name} {names.person.filter(n => n.id === persons.filter(p => p.id === person_id)[0].person)[0].label}</h2>
                                <div className={`flex flex-wrap`}>
                                    <Btn color="button" method="back" onClick={() => {
                                        setPerson_id(null);
                                        setIsCheckedDocs(false)
                                    }}>Назад</Btn>
                                    <Btn color="button" method="add" className={`mx-2`}
                                         onClick={addCompanyDocsHandler}>
                                        Добавить
                                        документы СРО для
                                        организации
                                    </Btn>
                                </div>
                                <div className="flex flex-wrap">
                                    {persons.find(p => p.id === person_id)?.docs?.map(doc =>
                                        <div key={doc.id} className={styles.card}>
                                            <div className={styles.label}>СРО</div>
                                            <div className="p-2">
                                                <div className={`flex-1`}>
                                                    <div>Номер документа:</div>
                                                    <div>{doc.number}</div>
                                                    <div>Дата выдачи документа:</div>
                                                    <div>{doc.date}</div>
                                                    {doc.organization_issued_document &&
                                                        <>
                                                            <div>Организация, выдавшая документ:</div>
                                                            <div>{doc.organization_issued_document}</div>
                                                        </>
                                                    }
                                                    <Link className={`${styles.link} my-auto`}
                                                          to={`${userSettings.fileServer}${doc.file}`}
                                                          target="_blank">Скачать</Link>
                                                </div>
                                                <div className={`flex-none grid xl:grid-cols-2 justify-items-center`}>
                                                    <Btn
                                                        color="button"
                                                        method="edit"
                                                        onClick={() => editCompanyDocsHandler(doc)}>
                                                        Редактировать
                                                    </Btn>
                                                    <Btn
                                                        method="remove"
                                                        onClick={() => deleteCompanyDocsHandler(doc.id)}>
                                                        Удалить
                                                    </Btn>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <h2>Представители {persons.filter(p => p.id === person_id)[0].name} {names.person.filter(n => n.id === persons.filter(p => p.id === person_id)[0].person)[0].label}</h2>
                                <div className={`flex flex-wrap`}>
                                    <Btn color="button" method="back" onClick={() => {
                                        setPerson_id(null);
                                    }}>Назад</Btn>
                                    <Btn color="button" method="add" className={`mx-2`}
                                         onClick={addRepresentativesHandler}>Добавить
                                        представителя</Btn>
                                    {persons.filter(p => p.id === person_id)[0].representatives.filter(r => !r.is_active).length > 0 &&
                                        <Btn color="button" method="view" onClick={returnRepresentativesHandler}>Уволенные
                                            представители</Btn>}
                                </div>
                                <div className={`columns-1 lg:columns-2 xl:columns-3 gap-2 space-y-2`}>
                                    {persons.filter(p => p.id === person_id)[0].representatives.filter(r => r.is_active).map(r =>
                                        <div key={r.id} className={`${styles.card}`}>
                                            <div className={styles.label}>{r.name}</div>
                                            <Grids cols={2} className={`px-2`}>
                                                <div>Должность</div>
                                                <div>{r.position}</div>
                                                <div>Телефон</div>
                                                <div>{PrintPhone(r.phone)}</div>
                                                <Btn className={`mx-auto`} color="button" method="edit"
                                                     onClick={() => editRepresentativesHandler(r)}>Редактировать</Btn>
                                                <Btn className={`mx-auto`} method="remove"
                                                     onClick={() => removeRepresentativesHandler(r)}>Удалить</Btn>
                                            </Grids>
                                            <div className="flex justify-center">
                                                {persons.length <= 1
                                                    ? <Tooltip title="Отсутствуют другие лица" placement="top">
                                             <span>
                                              <Button disabled>Копировать</Button>
                                             </span>
                                                    </Tooltip>
                                                    : <Button onClick={() => copyRepresentativeHandler(person_id, r)}
                                                              className="w-fit">Копировать</Button>

                                                }

                                            </div>
                                            <WatchDocs
                                                docs={r.docs}
                                                addDocsHandler={() => addDocsHandler(r.id, r.docs)}
                                                id={r.id}
                                                editDocsHandler={editDocsHandler}
                                                names={names.docs}
                                                userSettings={userSettings}
                                            />
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                    }
                </>
            }
        </UserSection>
    );
}

export default CompanyRepresentatives;
