import {Box, Button} from "@mui/material";
import React from "react";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export default function AskUser({success, reject}) {

    return (
        <Box className={`grid grid-cols-2 items-center gap-6`}>
            <Button
                onClick={success}
                color={"success"}
                variant="contained"
                startIcon={<CheckOutlinedIcon />}
            >
                Подтвердить
            </Button>
            <Button
                onClick={reject}
                color={"error"}
                variant="contained"
                startIcon={<CancelOutlinedIcon />}
            >
                Отклонить
            </Button>
        </Box>
    );
}