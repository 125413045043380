import {useState} from 'react';
import useRepresentativePersons from './useRepresentativePersons';

const useRepresentativeSelect = (personList) => {
    const [representativesId, setRepresentativesId] = useState({
        builder_control_id: "",
        other_inspection_id: "",
        other_inspection_id_2: "",
    });

    const handleRepresentativesOnChange = (event) => {
        const {name, value} = event.target;
        const splitedValue = value.split("_");
        setRepresentativesId(prev =>
            ({...prev, [name]: splitedValue[1]})
        );
    }

    const builderControl = useRepresentativePersons(representativesId.builder_control_id, personList);
    const otherInspection = useRepresentativePersons(representativesId.other_inspection_id, personList);
    const otherInspection2 = useRepresentativePersons(representativesId.other_inspection_id_2, personList);

    const isSelectedRepresentatives = builderControl
    return {
        representativesState: {
            builderControl,
            otherInspection,
            otherInspection2
        },
        handleRepresentativesOnChange,
        representativesId,
        isSelectedRepresentatives
    };
};

export default useRepresentativeSelect;