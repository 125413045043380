import React from 'react';
import {Document, Font, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import {getCurrentDate} from "../../Work/Subcompany/KS-3/helpers/getCurrentDate";

Font.register({
    family: "Roboto",
    fonts: [
        {src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"},
        {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
            fontWeight: 600
        },
    ]
});

const styles = StyleSheet.create({
    body: {
        fontFamily: "Roboto",
        fontSize: 6,
        padding: 5
    },
    page: {
        display: "flex",
        flexDirection: "column",
    },
    title: {
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: 600
    },
    container: {
        display: "flex",
        flexDirection: "row",
    },
    block: {
        margin: "10px 0",
    },
    bigPadding: {
        padding: 10
    },
    boldBorder: {
        border: '1px solid black',
    },
    row: {
        display: "flex",
        flexDirection: "row",
    },
    rowHeight: {
        height: 30
    },
    smallHeader: {
        border: "0.5px solid black",
        margin: "-0.5px -0.5px 0 0"
    },
    textCenter: {
        textAlign: "center",
    },
    center: {
        alignItems: "center",
        justifyContent: "center"
    },
    end: {
        alignItems: "flex-end",
    },
    horizontalBreak: {
        width: "100%",
        borderBottom: "1px solid black",
    },
    underline: {
        borderBottom: "1px solid black",
    },
});

const KS3 = (props) => {
    const {
        company,
        subcompany,
        workDates,
        currentTender,
        logsForTable,
        summary,
        signatories
    } = props.fetchedInfo;
    const customer = props.fetchedInfo.personsList.find(p => p.person === 1);

    const currentDate = getCurrentDate();

    const writeIfExists = (...all) => {
        const text = all.filter(text => text?.length > 0).join(", ");
        return text.length > 0 ? text : " ";
    }

    return (
        <Document title='КС-3'>
            <Page style={styles.body}>
                <View style={[styles.block, styles.end]}>
                    <Text>Унифицированная форма № КС-3</Text>
                    <Text>Утверждена Постановлением Госкомстата России</Text>
                    <Text>от 11.11.99 № 100</Text>
                </View>

                <View style={styles.container}>
                    <View style={{flex: 1}}>
                        <View style={[styles.end]}>
                            <View style={[styles.center, styles.smallHeader, {width: 90, height: 14, borderBottom: 0}]}>
                                <Text>
                                    Код
                                </Text>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={styles.row}>
                                <View style={{alignItems: "flex-end", justifyContent: "flex-end", marginRight: 1}}>
                                    <Text>Форма по ОКУД</Text>
                                </View>
                                <View style={[styles.center, styles.smallHeader, {
                                    width: 90,
                                    borderBottom: 0
                                }]}>
                                    <Text>
                                        0322001
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.row]}>
                            <Text>Инвестор</Text>
                            <View style={{marginHorizontal: 5, flex: 1}}>
                                <View style={styles.textCenter}>
                                    <Text
                                        style={styles.horizontalBreak}>{writeIfExists(company.name, company.legal_address)}</Text>
                                </View>
                            </View>

                            <View style={styles.row}>
                                <View style={{alignItems: "flex-end", justifyContent: "flex-end", marginRight: 1}}>
                                    <Text>по ОКПО</Text>
                                </View>
                                <View style={[styles.center, styles.smallHeader, {
                                    width: 90,
                                    borderBottom: 0,
                                    height: "100%"
                                }]}>
                                    <Text>
                                        {""}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={[styles.row, {width: 400, justifyContent: "space-between"}]}>
                                <View>
                                    <Text>(организация, адрес, телефон, факс)</Text>
                                </View>

                                <View style={styles.end}>
                                    <View style={[styles.smallHeader, {width: 90, borderBottom: 0}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>


                        <View style={[styles.row]}>
                            <Text>Заказчик (Генподрядчик)</Text>
                            <View style={{marginHorizontal: 5, flex: 1}}>
                                <View style={styles.textCenter}>
                                    <Text
                                        style={styles.horizontalBreak}>{writeIfExists(customer?.name, customer?.legal_address)}</Text>
                                </View>
                            </View>

                            <View style={styles.row}>
                                <View style={{alignItems: "flex-end", justifyContent: "flex-end", marginRight: 1}}>
                                    <Text>по ОКПО</Text>
                                </View>
                                <View style={[styles.center, styles.smallHeader, {
                                    width: 90,
                                    borderBottom: 0,
                                    borderTop: 0,
                                }]}>
                                    <Text>
                                        {" "}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={[styles.row, {width: 400, justifyContent: "space-between"}]}>
                                <View>
                                    <Text>(организация, адрес, телефон, факс)</Text>
                                </View>

                                <View style={styles.end}>
                                    <View style={[styles.smallHeader, {width: 90, borderBottom: 0}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.row]}>
                            <Text>Подрядчик (Субподрядчик)</Text>
                            <View style={{marginHorizontal: 5, flex: 1}}>
                                <View style={styles.textCenter}>
                                    <Text style={styles.horizontalBreak}>{writeIfExists(subcompany.name, subcompany.legal_address)}</Text>
                                </View>
                            </View>

                            <View style={styles.row}>
                                <View style={{alignItems: "flex-end", justifyContent: "flex-end", marginRight: 1}}>
                                    <Text>по ОКПО</Text>
                                </View>
                                <View style={[styles.center, styles.smallHeader, {
                                    width: 90,
                                    borderBottom: 0,
                                    borderTop: 0,
                                }]}>
                                    <Text>
                                        {" "}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={[styles.row, {width: 400, justifyContent: "space-between"}]}>
                                <View>
                                    <Text>(организация, адрес, телефон, факс)</Text>
                                </View>

                                <View style={styles.end}>
                                    <View style={[styles.smallHeader, {width: 90, borderBottom: 0}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.row]}>
                            <Text>Стройка</Text>
                            <View style={{marginHorizontal: 5, flex: 1}}>
                                <View style={styles.textCenter}>
                                    <Text style={styles.horizontalBreak}>{" "}</Text>
                                </View>
                            </View>

                            <View style={styles.row}>
                                <View style={{alignItems: "flex-end", justifyContent: "flex-end", marginRight: 1}}>
                                    <Text>по ОКПО</Text>
                                </View>
                                <View style={[styles.center, styles.smallHeader, {
                                    width: 90,
                                    borderTop: 0,
                                }]}>
                                    <Text>
                                        {" "}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={[styles.row, {width: 400, justifyContent: "space-between"}]}>
                                <View>
                                    <Text>(наименование, адрес)</Text>
                                </View>

                                <View style={styles.end}>
                                    <View style={[styles.smallHeader, {width: 90, borderBottom: 0}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={[styles.row]}>
                                <Text style={{marginRight: 1}}>Вид деятельности по ОКДП</Text>
                                <View style={styles.end}>
                                    <View style={[styles.smallHeader, {width: 90, borderTop: 0}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={[styles.row]}>
                                <Text style={{marginRight: 1}}>Договор подряда (контракт)</Text>

                                <View style={[styles.end, styles.row]}>
                                    <View
                                        style={[styles.smallHeader, {width: 30, textAlign: "right", paddingRight: 1}]}>
                                        <Text>номер</Text>
                                    </View>
                                    <View style={[styles.smallHeader, {width: 90, borderTop: 0}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={[styles.end, styles.row]}>
                                <View style={[styles.smallHeader, {width: 30, textAlign: "right", paddingRight: 1}]}>
                                    <Text>дата</Text>
                                </View>
                                <View style={[styles.row]}>
                                    <View style={[styles.center, styles.smallHeader, {width: 30, borderLeft: 0}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                    <View style={[styles.center, styles.smallHeader, {width: 30}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                    <View style={[styles.center, styles.smallHeader, {width: 31}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.end]}>
                            <View style={[styles.row]}>
                                <Text style={{marginRight: 1}}>Вид операции</Text>
                                <View style={styles.end}>
                                    <View style={[styles.smallHeader, {width: 90, borderTop: 0}]}>
                                        <Text>{" "}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={[styles.row, {marginLeft: 250, marginTop: 10}]}>
                    <View style={[styles.boldBorder, {width: 125, height: 40}]}>
                        <View style={styles.row}>
                            <View style={[
                                styles.smallHeader,
                                styles.center,
                                {width: 61, height: 20, borderLeft: 0}
                            ]}>
                                <Text>
                                    Номер документа
                                </Text>
                            </View>

                            <View style={[
                                styles.smallHeader,
                                styles.center,
                                {width: 63, height: 20}
                            ]}>
                                <Text>
                                    Дата составления
                                </Text>
                            </View>


                        </View>

                        <View style={styles.row}>
                            <View style={[
                                styles.smallHeader,
                                styles.center,
                                {width: 61, height: 20, borderLeft: 0}
                            ]}>
                                <Text></Text>
                            </View>

                            <View style={[
                                styles.smallHeader,
                                styles.center,
                                {width: 63, height: 20}
                            ]}>
                                <Text>{currentDate}</Text>
                            </View>
                        </View>
                    </View>

                    <View style={[styles.boldBorder, {width: 125, height: 40, marginLeft: 10}]}>
                        <View style={[styles.center, {height: 14}]}>
                            <Text>Отчетный период</Text>
                        </View>
                        <View style={styles.row}>
                            <View style={[
                                styles.smallHeader,
                                styles.center,
                                {width: 61, height: 10, borderLeft: 0}
                            ]}>
                                <Text>
                                    с
                                </Text>
                            </View>

                            <View style={[
                                styles.smallHeader,
                                styles.center,
                                {width: 63, height: 10}
                            ]}>
                                <Text>
                                    по
                                </Text>
                            </View>
                        </View>

                        <View style={styles.row}>
                            <View style={[
                                styles.smallHeader,
                                styles.center,
                                {width: 61, height: 16, borderLeft: 0}
                            ]}>
                                <Text>{workDates.minDate}</Text>
                            </View>

                            <View style={[
                                styles.smallHeader,
                                styles.center,
                                {width: 63, height: 16}
                            ]}>
                                <Text>{workDates.maxDate}</Text>
                            </View>
                        </View>

                    </View>
                </View>

                <View style={[styles.textCenter, styles.title, {marginTop: 5}]}>
                    <Text>СПРАВКА</Text>
                    <Text>О СТОИМОСТИ ВЫПОЛНЕННЫХ РАБОТ И ЗАТРАТ</Text>
                </View>

                <View style={{marginTop: 15}}>
                    <View style={[styles.row, styles.smallHeader, {height: 50}]}>
                        <View style={[styles.center, styles.smallHeader, {width: 30, borderBottom: 0, borderLeft: 0}]}>
                            <Text>Номер по порядку</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {
                            width: 287,
                            borderBottom: 0,
                            paddingHorizontal: 20,
                            textAlign: "center"
                        }]}>
                            <Text>Наименование пусковых комплексов, этапов, объектов, видов выполненных работ,
                                оборудования, затрат</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 40, borderBottom: 0}]}>
                            <Text>Код</Text>
                        </View>

                        <View style={[styles.smallHeader, styles.textCenter, {width: 240, borderBottom: 0}]}>
                            <View style={[styles.center, {height: 20}]}>
                                <Text>Стоимость выполненных работ и затрат, руб.</Text>
                            </View>
                            <View style={[styles.row, {height: 30}]}>
                                <View style={[styles.center, styles.smallHeader, {
                                    width: 80,
                                    borderLeft: 0,
                                    borderBottom: 0
                                }]}>
                                    <Text>с начала проведения работ</Text>
                                </View>

                                <View style={[styles.center, styles.smallHeader, {
                                    width: 80,
                                    borderBottom: 0
                                }]}>
                                    <Text>с начала года</Text>
                                </View>

                                <View style={[styles.center, styles.smallHeader, {
                                    width: 80,
                                    borderBottom: 0
                                }]}>
                                    <Text>в том числе за отчетный период</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={[styles.row, styles.smallHeader]}>
                        <View
                            style={[styles.center, styles.smallHeader, {
                                width: 30,
                                borderLeft: 0,
                                borderBottom: 0,
                            }]}>
                            <Text>1</Text>
                        </View>

                        <View
                            style={[styles.center, styles.smallHeader, {
                                width: 287,
                                borderLeft: 0,
                                borderBottom: 0,
                            }]}>
                            <Text>2</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 40, borderBottom: 0}]}>
                            <Text>3</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 80.5, borderBottom: 0}]}>
                            <Text>4</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 80.5, borderBottom: 0}]}>
                            <Text>5</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 80, borderBottom: 0}]}>
                            <Text>6</Text>
                        </View>
                    </View>

                    <View style={[styles.row, styles.smallHeader]}>
                        <View
                            style={[styles.center, styles.smallHeader, {
                                width: 30,
                                borderLeft: 0,
                                borderBottom: 0,
                            }]}>
                            <Text></Text>
                        </View>

                        <View
                            style={[styles.smallHeader, {
                                width: 287,
                                borderLeft: 0,
                                borderBottom: 0,
                                padding: 5
                            }]}>
                            <Text>Всего работ и затрат, включаемых в стоимость работ:</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 40, borderBottom: 0}]}>
                            <Text></Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 80.5, borderBottom: 0}]}>
                            <Text>{summary.start}</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 80.5, borderBottom: 0}]}>
                            <Text>{summary.yearStart}</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 80, borderBottom: 0}]}>
                            <Text>{summary.period}</Text>
                        </View>
                    </View>

                    <View style={[styles.row, styles.smallHeader]}>
                        <View
                            style={[styles.center, styles.smallHeader, {
                                width: 30,
                                borderLeft: 0,
                                borderBottom: 0,
                            }]}>
                            <Text></Text>
                        </View>

                        <View
                            style={[styles.smallHeader, {
                                width: 287,
                                borderLeft: 0,
                                borderBottom: 0,
                                padding: 5
                            }]}>
                            <Text>в том числе:</Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 40, borderBottom: 0}]}>
                            <Text></Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 80.5, borderBottom: 0}]}>
                            <Text></Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 80.5, borderBottom: 0}]}>
                            <Text></Text>
                        </View>

                        <View style={[styles.center, styles.smallHeader, {width: 80, borderBottom: 0}]}>
                            <Text></Text>
                        </View>
                    </View>

                    {logsForTable.map((work, index) =>
                        <View key={"first_table_" + work.id}>
                            <View style={[styles.row, styles.smallHeader]}>
                                <View
                                    style={[styles.center, styles.smallHeader, {
                                        width: 30,
                                        borderLeft: 0,
                                        borderBottom: 0,
                                    }]}>
                                    <Text>{index + 1}</Text>
                                </View>

                                <View
                                    style={[styles.smallHeader, {
                                        paddingHorizontal: 5,
                                        width: 287,
                                        borderLeft: 0,
                                        borderBottom: 0,
                                        textAlign: "left"
                                    }]}>
                                    <Text>{work.name_works}</Text>
                                </View>

                                <View style={[styles.center, styles.smallHeader, {width: 40, borderBottom: 0}]}>
                                    <Text></Text>
                                </View>

                                <View style={[styles.center, styles.smallHeader, {width: 80.5, borderBottom: 0}]}>
                                    <Text>{work.start}</Text>
                                </View>

                                <View style={[styles.center, styles.smallHeader, {width: 80.5, borderBottom: 0}]}>
                                    <Text>{work.yearStart}</Text>
                                </View>

                                <View style={[styles.center, styles.smallHeader, {width: 80, borderBottom: 0}]}>
                                    <Text>{work.totalCost}</Text>
                                </View>
                            </View>
                        </View>
                    )}

                    <View style={[styles.end]}>
                        <View style={styles.row}>
                            <Text style={{marginRight: 5}}>Итого</Text>
                            <View style={[styles.smallHeader, styles.center, {width: 78.7, textAlign: "center"}]}>
                                <Text>{summary.period}</Text>
                            </View>
                        </View>
                    </View>

                    <View style={[styles.end]}>
                        <View style={styles.row}>
                            <Text style={{marginRight: 5}}>Сумма НДС</Text>
                            <View style={[styles.smallHeader, styles.center, {width: 78.7, textAlign: "center"}]}>
                                <Text>{" "}</Text>
                            </View>
                        </View>
                    </View>

                    <View style={[styles.end]}>
                        <View style={styles.row}>
                            <Text style={{marginRight: 5}}>Всего с учетом НДС</Text>
                            <View style={[styles.smallHeader, styles.center, {width: 78.7, textAlign: "center"}]}>
                                <Text>{" "}</Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={[styles.row, {marginTop: 20, gap: 10}]}>
                    <Text>Заказчик (Генподрядчик)</Text>
                    <View style={{width: 175, marginLeft: 5}}>
                        <View style={styles.textCenter}>
                            <Text style={styles.horizontalBreak}>{signatories.company.position}</Text>
                        </View>

                        <View style={styles.textCenter}>
                            <Text>(должность)</Text>
                        </View>
                    </View>

                    <View style={{width: 80}}>
                        <View style={styles.textCenter}>
                            <Text style={styles.horizontalBreak}>{" "}</Text>
                        </View>

                        <View style={styles.textCenter}>
                            <Text>(подпись)</Text>
                        </View>
                    </View>

                    <View style={{flex: 1}}>
                        <View style={styles.textCenter}>
                            <Text style={styles.horizontalBreak}>{signatories.company.short_name}</Text>
                        </View>

                        <View style={styles.textCenter}>
                            <Text>(расшифровка подписи)</Text>
                        </View>
                    </View>

                </View>

                <Text style={{marginTop: 10}}>М.П.</Text>

                <View style={[styles.row, {marginTop: 20, gap: 10}]}>
                    <Text>Подрядчик (Субподрядчик)</Text>
                    <View style={{width: 175, marginLeft: 5}}>
                        <View style={styles.textCenter}>
                            <Text style={styles.horizontalBreak}>{signatories.subcompany.position}</Text>
                        </View>

                        <View style={styles.textCenter}>
                            <Text>(должность)</Text>
                        </View>
                    </View>

                    <View style={{width: 80}}>
                        <View style={styles.textCenter}>
                            <Text style={styles.horizontalBreak}>{" "}</Text>
                        </View>

                        <View style={styles.textCenter}>
                            <Text>(подпись)</Text>
                        </View>
                    </View>

                    <View style={{flex: 1}}>
                        <View style={styles.textCenter}>
                            <Text style={styles.horizontalBreak}>{signatories.subcompany.short_name}</Text>
                        </View>

                        <View style={styles.textCenter}>
                            <Text>(расшифровка подписи)</Text>
                        </View>
                    </View>

                </View>

                <Text style={{marginTop: 10}}>М.П.</Text>
            </Page>
        </Document>
    )
};

export default KS3;