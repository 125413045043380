import React, {useState} from "react";
import styles from "./inputs.module.css";
import {useFormContext} from "react-hook-form";
import InputMask from "react-input-mask-next";
import {toUnicode} from "punycode";

const RegistrationInput = (
    {
        name,
        label,
        type,
        length,
        disabled,
        defaultValue,
        notRequired,
        options,
        onChange,
        onKeyDown,
        min,
        max,
        step,
        className,
        firstEmpty,
        placeholder,
        multiple,
        allowedFileTypes,
        sig,
        error,
    }
) => {

    const {register, setValue, formState: {errors}} = useFormContext();

    const inputProps = {
        className,
        name,
        type,
        defaultValue: defaultValue !== undefined ? defaultValue : "",
        step,
        min: min || 0,
        max: max || undefined,
        onChange,
        onKeyDown,
        ...register(name, {
            validate: (value) => {
                if (notRequired) return true;

                if (typeof value === "undefined") {
                    return "Поле обязательно для заполнения";
                }

                if (type === 'file' && (allowedFileTypes || sig)) {
                    const allowedExtensions = allowedFileTypes ? allowedFileTypes.split(',').map(ext => ext.trim()) : [];
                    const fileName = value[0]?.name;
                    const fileExtension = fileName ? fileName.split('.').pop().toLowerCase() : null;
                    const files = value;

                    if (!fileName) {
                        return "Загрузите файл";
                    }

                    if (!sig && files.length > 0) {

                        let check = null;

                        for (let i = 0; i < files.length; i++) {
                            const fileName = files[i]?.name;
                            const fileExtension = fileName ? fileName.split('.').pop().toLowerCase() : null;
                            if (!allowedExtensions.includes(`.${fileExtension}`)) check = true;
                        }

                        if (!check) return;
                        else return `Недопустимый тип файла, допустимые форматы ${allowedExtensions}`
                    }

                    if (sig) {

                        const lowercaseFileName = value[0]?.name.toLowerCase();
                        const isSigFile = lowercaseFileName.endsWith('.sig') || value[0]?.type.includes('pdf');

                        // console.log(`\n isSigFile`, isSigFile);

                        if (!isSigFile) {
                            return `Недопустимый тип файла, допустимые форматы *.sig и *.pdf`;
                        }
                    }

                    // console.log(
                    //     `\n file`, value[0],
                    //     `\n error`, error,
                    //     `\n errors`, errors, errors[name], name,
                    //     `\n error`, (error ? error : errors[name]),
                    //     `\n fileName`, !fileName, fileName,
                    //     `\n fileExtension`, fileExtension,
                    //     `\n files`, files,
                    //     `\n sig`, sig,
                    //     `\n lowercaseFileName`, lowercaseFileName,
                    // );

                    if (allowedExtensions.length > 0 && !allowedExtensions.includes(fileExtension) && !sig) {
                        return `Недопустимый тип файла, допустимые форматы ${allowedExtensions}`;
                    }
                }

                return (
                    (length > 0 && value.length === length) ||
                    value.length > 0 ||
                    "Некорректное значение"
                );
            },
        }),
    };
    if (step !== undefined && step !== false) inputProps.step = step.toString();

    const [isOpen, setIsOpen] = useState(false);

    let inputComponent;

    if (type === "select") {
        inputComponent = (
            <select
                name={name}
                {...register(name)}
                onClick={() => setIsOpen && setIsOpen(!isOpen)}
            >
                {firstEmpty && (
                    <option value="" className="hidden">
                        {label}
                    </option>
                )}
                {options.map((option) => (
                    <option key={option.id} value={option.id}>
                        {option.name}
                    </option>
                ))}
            </select>
        );
    } else if (type === "tel") {
        inputComponent = (
            <InputMask
                mask="+7 (999) 999 99 99"
                placeholder="+7 (___) ___ __ __"
                defaultValue={defaultValue}
                {...register(name, {
                    validate: (value) => {
                        if (value.replace(/[_ ]/g, "").length < 14)
                            return "Телефон неправильно введён";
                        return true;
                    },
                })}
                onPaste={(e) => {
                    const number = e.clipboardData.getData("Text");
                    const formattedNumber = number.replace(/7+/i, "");
                    // Assuming you're using a state hook for 'phone'
                    // setPhone(formattedNumber);
                }}
            />
        );
    } else if (type === "file") {
        inputComponent = (
            <div>
                <input type="file" multiple={multiple} {...inputProps} />
                {errors[name] && (
                    <p style={{color: "red"}}>{errors[name].message}</p>
                )}
            </div>
        );
    } else if (type === "textarea") {
        inputComponent = (
            <textarea
                minLength={min}
                maxLength={max}
                placeholder={placeholder}
                {...register(name)}
            />
        );
    } else if (disabled) {
        inputComponent = (
            <fieldset disabled>
                <input {...inputProps} />
            </fieldset>
        );
    } else if (type === "email") {
        inputComponent = (
            <input
                type="text"
                {...register(name, {
                    required: 'Email обязателен',
                    pattern: {
                        value: /^[a-zа-яA-ZА-Я0-9._]+@[a-zа-яA-ZА-Я0-9._]+\.[a-zа-яA-ZА-Я0-9._]{2,}$/,
                        message: 'Введите корректный email',
                    },
                })}
                onChange={(e) => {
                    onChange && onChange(e);
                    setValue(name, toUnicode(e.target.value));
                    setValue("admin_email", toUnicode(e.target.value));
                }}
            />
        );
    } else if (type === "date") {
        inputComponent = (
            <input type="date" {...inputProps} min={min} max={max}/>
        );
    } else {
        inputComponent = <input {...inputProps} />;
    }


    return (
        <div
            className={`${styles.field} ${
                (error || errors[name]) ? styles.invalid : ""
            } relative`}
        >
            <label htmlFor={name}>{label}</label>
            {inputComponent}
        </div>
    );
};

export default RegistrationInput;
