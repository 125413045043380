export const getTotalCosts = (estimate) => {
    let value = 0;
    const costByUnit = (Number(estimate.unit_cost_smr) + Number(estimate.unit_cost_materials));

    estimate.work_log.forEach(log => {
        if (log.certificates_completed_work.length === 0) value += Number(log.value)
    })
    value = parseFloat(value.toFixed(4));
    const startTotalCost = parseFloat((value * costByUnit).toFixed(2));

    return {startTotalCost};
}