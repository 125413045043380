import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import tableStyles from "../../UI/Tables/table.module.css";
import styles from "./dash.module.css";
import Btn from "../../UI/Buttons/Btn";
import {ReactComponent as AccessRejected} from "../../UI/Icons/Administrator/accessRejected.svg";
import {ReactComponent as AccessGranted} from "../../UI/Icons/Administrator/accessGranted.svg";
import {ReactComponent as AddProject} from "../../UI/Icons/Administrator/addProject.svg";
import Popup from "../../UI/Popup/Popup";
import UserContext from "../../../store/user/user-context";
import {useNavigate} from "react-router-dom";
import {GetUserInfo} from "./GetUserInfo";
import Warnings from "../../UI/Sections/Warnings";

const ProjectsManagement = () => {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '1') navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = window.localStorage.getItem('company');
    const [popupShow, setPopupShow] = useState(false);

    const [companyProjects, setCompanyProjects] = useState();
    const [answers, setAnswers] = useState(null);

    const updateProject = async (requests, project_id) => {

        if (Object.keys(requests).length > 0) {

            // console.log(`\n updateProject`, requests, project_id);

            let changedProject = {};

            companyProjects.forEach(project => {

                // console.log(`\n `, project.id, project_id, project.id === project_id);

                if (project.id === project_id) {
                    const projectUpdate = {};
                    Object.keys(project).forEach(key => {

                        // console.log(`\n hasOwnProperty`, requests, key, requests[key], requests[key] && requests.hasOwnProperty(key));
                        projectUpdate[key] = requests.hasOwnProperty(key) ? requests[key] : project[key];

                        // console.log(`\n `, projectUpdate[key]);
                    });
                    changedProject = projectUpdate;
                }
            });

            // console.log(`\n changedProject`, changedProject);

            const requestBody = new FormData();
            Object.keys(changedProject).forEach(key => {
                requestBody.append(key, changedProject[key]);
            });

            // console.log(`\n requestBody`, requestBody);

            try {

                const updateProjectsHandler = await axios.put(
                    `${userSettings.api}/company/${company_id}/projects/projects/${project_id}/`,
                    requestBody,
                    {
                        headers: userSettings.headers.postForm
                    }
                );

                const updateProjects = companyProjects.map(project => {
                    return (updateProjectsHandler.data.data.id === project.id) ? updateProjectsHandler.data.data : project;
                });

                // console.log(`\n updateProjects`, updateProjects);

                setAnswers(<Warnings correct>Проект обновлен</Warnings>);
                setCompanyProjects(updateProjects);

            } catch (error) {

                setAnswers(
                    <Warnings>
                        Ошибка обновления данных проекта - {error.response.data.errors ?
                        error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                        :
                        error.response.status
                    }
                    </Warnings>
                )
            }
        }

        setPopupShow(false);
    }

    const addProjectHandler = () => {

        const addProject = async (data) => {

            try {
                const formData = new FormData();
                Object.keys(data).forEach(key => {
                    formData.append(key, data[key]);
                });
                // formData.append('is_main', companyProjects.length <= 0);

                axios.post(
                    `${userSettings.api}/company/${company_id}/projects/projects/`,
                    formData,
                    {headers: userSettings.headers.postForm}
                ).then(response => {
                    console.log(`\n `, response.data.data);
                    if (response.data.data) {
                        window.localStorage.setItem("project", response.data.data.id)
                        setCompanyProjects(prevProjects => [...prevProjects, response.data.data]);
                        setPopupShow(false);
                        setAnswers(<Warnings correct>Проект добавлен</Warnings>);
                    }
                })
                    .catch(error => console.log(`\n error`, (error.code === 'ERR_BAD_REQUEST') ? 'ERR_BAD_REQUEST' : error.data));
            } catch (error) {
                setAnswers(<Warnings>
                    Ошибка добавления проекта - {error.response.data.errors ?
                    error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                    :
                    error.response.status
                }
                </Warnings>)
                console.log(`\n addProjectHandler error`, error.response);
            }
        }

        setPopupShow(<Popup
            title="Добавить проект"
            form={{
                fields: [
                    {
                        type: "text",
                        name: "name",
                        label: "Наименование проекта",
                    },
                    {
                        type: "select",
                        name: "action",
                        label: "Доступ к проекту",
                        options: [
                            {
                                "id": 1,
                                "name": "Открыт"
                            },
                            {
                                "id": 0,
                                "name": "Закрыт"
                            },
                        ],
                    }
                ],
                submit: "Добавить"
            }}
            onSubmit={addProject}
            grid={2}
            onCloseModal={() => setPopupShow(false)}
        />);
    }
    const changeNameHandler = (id, nameProject) => {

        const renameProject = (data) => {

            console.log(`\n data`, data);

            updateProject({name: data.name,}, id)
        }

        setPopupShow(<Popup
            title={`Переименовать проект ${nameProject}`}
            form={{
                fields: [
                    {
                        type: "text",
                        name: "name",
                        label: "Наименование проекта",
                        defaultValue: nameProject
                    },
                ],
                submit: "Сохранить"
            }}
            onSubmit={renameProject}
            onCloseModal={() => setPopupShow(false)}
        />);
    }
    const accessProjectHandler = (id, state) => {

        let currentProject = companyProjects.filter(project => project.id === id)[0];

        currentProject = {
            "name": currentProject.name,
            "action": state
        }
        updateProject(currentProject, id);
    }

    useEffect(() => {
        GetUserInfo(userSettings, company_id, null, setCompanyProjects);
    }, [userSettings, company_id]);

    return (
        <React.Fragment>
            {popupShow}
            <h1>Управление проектами</h1>
            {answers && answers}
            <div className="w-max"><Btn color="button" icon={<AddProject/>} onClick={addProjectHandler}>Добавить
                проект</Btn></div>
            <div className={tableStyles.container}>
                {(companyProjects && companyProjects.length > 0) ?
                    <table className={tableStyles.table}>
                        <thead>
                        <tr>
                            <th>Наименование проекта</th>
                            <th>Статус</th>
                            <th>Управление</th>
                        </tr>
                        </thead>
                        <tbody>
                        {companyProjects.map(project => {

                            return (
                                <tr key={project.id}>
                                    <td>
                                        <span className={styles['row-name']}>{project.name}</span>
                                        <Btn color="button" method="edit" onClick={() => {
                                            changeNameHandler(project.id, project.name)
                                        }}>Редактировать</Btn>
                                    </td>
                                    <td>
                                        {project.action === 1 ?
                                            <div className={`${styles.status}`}>
                                                <AccessGranted className={styles.green}/>
                                                <span>Доступ предоставлен</span>
                                            </div>
                                            :

                                            <div className={`${styles.status}`}>
                                                <AccessRejected className={styles.red}/>
                                                <span>Доступ приостановлен</span>
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        {project.action === 1 ?
                                            <div className="w-max mx-auto">
                                                <Btn color="red" method="cancel"
                                                     onClick={() => accessProjectHandler(project.id, 0)}>Приостановить
                                                    доступ</Btn>
                                            </div>
                                            :
                                            <div className="w-max mx-auto">
                                                <Btn color="button" method="edit"
                                                     onClick={() => accessProjectHandler(project.id, 1)}>Возобновить
                                                    доступ</Btn>
                                            </div>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    : <div className='w-fit'>
                        <Warnings>
                            Отсутствуют проекты
                        </Warnings>
                    </div>}
            </div>
        </React.Fragment>
    );
}

export default ProjectsManagement;