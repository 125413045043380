import {useEffect, useState} from 'react';
import axios from 'axios';
import {useCookies} from 'react-cookie';

const useTenderLogic = (api) => {
    const [authError, setAuthError] = useState();
    const [cookies] = useCookies(['access']);
    const [tenders, setTenders] = useState([]);
    const [subCompanies, setSubCompanies] = useState(null);

    // console.log(`\n subCompanies`, subCompanies);

    const authorizationHandler = (data) => {
        // axios.post(
        //     `${api}/auth/login/`,
        //     {
        //         email: data.login,
        //         password: data.password,
        //     },
        //     { headers: { accept: 'application/json', 'Content-Type': 'application/json' } }
        // )
        //     .then((response) => {
        //         console.log(`\n `, response.data);
        //         // setCookie('access', response.data.access);
        //     })
        //     .catch((error) => {
        //         console.log('\n error', error.response.data);
        //         setAuthError(error.response.data.errors[0].detail);
        //     });
    };

    useEffect(() => {

        axios.get(
            `${api}/tenders/`,
            {headers: {accept: 'application/json'}}
        )
            .then((response) => {
                setTenders([...response.data].sort((a, b) => a.project - b.project));
            })
            .catch((error) => {
                console.log('\n error', error.response.data);
            });

        if (cookies.access && cookies.access.length > 0) {
            axios.get(
                `${api}/user/`,
                {headers: {accept: 'application/json', authorization: `Bearer ${cookies.access}`}}
            )
                .then((response) => {
                    console.log(`\n `, response.data);
                    setSubCompanies(response.data.companies.filter(c => c.subcompany));
                })
                .catch((error) => {
                    console.log('\n error', error.response.data);
                });
        }
    }, [api, cookies.access]);

    return {
        authError,
        cookies,
        tenders,
        authorizationHandler,
        subCompanies,
    };
};

export default useTenderLogic;
