import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import tableStyles from "../../UI/Tables/table.module.css";
import styles from "./dash.module.css";
import Btn from "../../UI/Buttons/Btn";
import {ReactComponent as AccessGranted} from "../../UI/Icons/Administrator/accessGranted.svg";
import {ReactComponent as AccessRejected} from "../../UI/Icons/Administrator/accessRejected.svg";
import {ReactComponent as AccessComplete} from "../../UI/Icons/Administrator/accessComplete.svg";
import Popup from "../../UI/Popup/Popup";
import Grids from "../../UI/Grids/Grids";
import UserContext from "../../../store/user/user-context";
import Warnings from "../../UI/Sections/Warnings";

const ContractorsManagement = (props) => {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '1') navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = window.localStorage.getItem('company');

    const [popupShow, setPopupShow] = useState(false);
    const [companyContactors, setCompanyContactors] = useState([]);
    useEffect(() => {
        async function getCompany() {
            return await axios.get(
                `${userSettings.api}/company/${company_id}/subcontractors`,
                userSettings.headers.get
            )
        }

        getCompany()
            .then(response => setCompanyContactors(response.data))
    }, [userSettings.api, company_id, userSettings.token]);

    const contractAccessHandler = (id, state) => {

        const contractAccess = () => {

            axios.post(
                `${userSettings.api}/company/${company_id}/subcompanies/${id}`,
                {"contract": +state},
                userSettings.headers.post
            ).then(response => {

                if (response.statusText === "OK") {
                    // setPopupShow(false);
                    window.location.reload();
                }
            }).catch(error => {
                console.error(error);
            });
        }

        const template = {
            1: "Вы уверены, что хотите восстановить доступ подрядчику",
            2: "Вы уверены, что хотите приостановить доступ подрядчику",
            3: "Вы уверены, что хотите завершить договор подрядчика",
        }

        setPopupShow(<Popup
            title={template[state]}
            content={<div>
                <Grids cols={2} className="justify-items-center">
                    <Btn color="warning" onClick={contractAccess}>Да</Btn>
                    <Btn color="button" onClick={() => setPopupShow(false)}>Нет</Btn>
                </Grids>
            </div>}
            onCloseModal={() => setPopupShow(false)}
        />);
    }

    console.log(companyContactors)
    return (
        <React.Fragment>
            {popupShow}
            <h1>Управление подрядчиками</h1>
            <div className={tableStyles.container}>
                {(companyContactors && companyContactors.length > 0) ?
                    <table className={tableStyles.table}>
                        <thead>
                        <tr>
                            <th>Наименование подрядчика</th>
                            <th>Статус</th>
                            <th>Управление</th>
                        </tr>
                        </thead>
                        <tbody>
                        {companyContactors.map(contractor => {

                            return (
                                <tr key={contractor.id}>
                                    <td>
                                        <span className={styles['row-name']}>{contractor.full_name}</span>
                                        <div>{contractor.email}</div>
                                    </td>
                                    <td>
                                        {contractor.contract === 1 ?
                                            <div className={`${styles.status}`}>
                                                <AccessGranted className={styles.green}/>
                                                <span>Доступ предоставлен</span>
                                            </div>
                                            :
                                            contractor.contract === 2 ?
                                                <div className={`${styles.status}`}>
                                                    <AccessRejected className={styles.red}/>
                                                    <span>Доступ приостановлен</span>
                                                </div>
                                                :
                                                <div className={`${styles.status}`}>
                                                    <AccessComplete className={styles.green}/>
                                                    <span>Договор завершен</span>
                                                </div>
                                        }
                                    </td>
                                    <td>
                                        {contractor.contract !== 3 ?
                                            contractor.contract === 1 ?
                                                <div className="w-max mx-auto">
                                                    <Btn color="red" method="cancel"
                                                         onClick={() => contractAccessHandler(contractor.subcompany_id, 2)}>Приостановить
                                                        доступ</Btn>
                                                    <Btn color="red" method="edit"
                                                         onClick={() => contractAccessHandler(contractor.subcompany_id, 3)}>Завершить
                                                        договор</Btn>
                                                </div>
                                                :
                                                <div className="w-max mx-auto">
                                                    <Btn color="button" method="edit"
                                                         onClick={() => contractAccessHandler(contractor.subcompany_id, 1)}>Восстановить
                                                        доступ</Btn>
                                                    <Btn color="red" method="edit"
                                                         onClick={() => contractAccessHandler(contractor.subcompany_id, 3)}>Завершить
                                                        договор</Btn>
                                                </div>
                                            : ''
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    : <div className='w-fit'>
                        <Warnings>Отсутствуют подрядчики</Warnings>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}

export default ContractorsManagement;