export const getWorkLogValueAndCost = (estimate) => {
    let value = 0;
    const costByUnit = parseFloat((Number(estimate.unit_cost_smr) + Number(estimate.unit_cost_materials)).toFixed(2));

    estimate.work_log.forEach(log => {
        if (log.certificates_completed_work.length === 0) value += Number(log.value)
    })
    value = parseFloat(value.toFixed(4));
    const totalCost = parseFloat((value * costByUnit).toFixed(2));

    return {value, costByUnit, totalCost}
}