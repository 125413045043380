import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import UserContext from "../../../store/user/user-context";
import {useLocation, useNavigate} from "react-router-dom";
import EvidenceBase from "./EvidenceBase";
import {getOrders} from "../../UI/GetInfo/getOrders";
import Warnings from "../../UI/Sections/Warnings";
import {changeLotStatusPost, getAnalyzeOrders} from "../../SendRequests/analyzeOrders";
import SubcompanyAnalyzeTable from "./SubcompanyAnalyzeTable";
import {Box, Modal, Typography} from "@mui/material";
import ContractDetails from "./ContractDetails";
import AskUser from "../../UI/Popup/AskUser";

export const contractStatuses = {
    1: "Открыт",
    2: "Приостановлен",
    3: "Завершён",
    4: "Расторгнут",
}

export default function QualityControlWorks() {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '0') navigate('/work/');
    const locationParam = new URLSearchParams(useLocation().search);

    const userSettings = useContext(UserContext);
    const company_id = +window.localStorage.getItem('company');
    const project_id = +window.localStorage.getItem('project');

    const [answers, setAnswers] = useState(null);
    const [orders, setOrders] = useState(null);
    const [analyzeOrders, setAnalyzeOrders] = useState(null);
    const [modal, setModal] = useState(null);
    const contract = analyzeOrders && analyzeOrders.find(c => c.id === locationParam.get('contract'));

    useEffect(() => {
        if (!userSettings || !company_id || !project_id) return;

        const getInfo = async () => {
            const gotOrders = await getOrders(userSettings, company_id, project_id);
            if (gotOrders) {
                setAnswers(<Warnings>{gotOrders?.answer}</Warnings>);
                const orders = gotOrders.length > 0 && gotOrders.filter(order => order.history.length > 1)
                // console.log(orders)

                return orders;
            }
        }

        getInfo()
            .then(setOrders)
    }, [userSettings, company_id, project_id]);
    // if (orders) console.log('\n QualityControlWorks orders', orders);
    // if (analyzeOrders) console.log('\n analyzeOrders', analyzeOrders)
    // if (contract && analyzeOrders) console.log('\n contract', contract, analyzeOrders.find(c => c.contract === contract));

    useEffect(() => {

        const getAnalyzeOrdersData = async () => {

            const analyzeOrders = await getAnalyzeOrders(userSettings, company_id, project_id);

            // console.log('\n analyzeOrders', analyzeOrders);

            setAnalyzeOrders(analyzeOrders);
        }

        getAnalyzeOrdersData();
    },[company_id, project_id, userSettings]);

    const terminateContract = async (tender_id, subcompany_id) => {
        // console.log('\n ', tender_id, subcompany_id);

        setAnswers(null);

        const changeLotStatus = async (userSettings, type, company_id, project_id, subcompany_id, tender_id) => {
            setModal(null);

            const response = await changeLotStatusPost(userSettings, 4, company_id, project_id, subcompany_id, tender_id);
            if (response?.success) {
                // console.log('\n ', response);
                setAnswers({
                    success: true,
                    message: `Статус договора на ${contractStatuses[type]} изменен успешно!`
                });
            } else {
                setAnswers(response);
            }
        };

        setModal(
            <Box className={`flex flex-col gap-2`}>
                <Typography variant={'h6'}>
                    Вы действительно хотите изменить статус договора на {contractStatuses[4]}?
                </Typography>
                <AskUser
                    success={() => changeLotStatus(userSettings, 4, company_id, project_id, subcompany_id, tender_id)}
                    reject={() => setModal(null)}
                />
            </Box>
        );
    }

    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        color: "black",
        width: '350px',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <UserSection>
            <h1>Контроль качества работ</h1>
            <div className='w-fit'>
                {answers?.props?.children && answers}
            </div>
            {modal && <Modal
                open={!!modal}
                onClose={() => setModal(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>{modal}</Box>
            </Modal>}
            {(contract && analyzeOrders) ?
                <ContractDetails
                    contract={contract}
                    userSettings={userSettings}
                /> :
                <React.Fragment>
                    <Box className={`h-full relative flex flex-col gap-2`}>
                        {orders?.length > 0 && <EvidenceBase
                            orders={orders}
                            userSettings={userSettings}
                        />}
                        {analyzeOrders && <SubcompanyAnalyzeTable
                            analyzeOrders={analyzeOrders}
                            terminateContract={terminateContract}
                        />}
                    </Box>
                </React.Fragment>
            }
        </UserSection>
    );
}