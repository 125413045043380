import styles from './Select.module.css';

const Select = (props) => {
    return (
        <div className={styles.container}>
            <div className={styles.collapsed}>
                <select
                    name={props.name}
                    onChange={props.handleOnChange}
                    className={`${styles.dropdown}
                      ${props.shownList === props.type
                        ? styles['dropdown-show']
                        : ''}
                    `}
                >
                    <option style={{display: 'none'}} value={''}>Выберите ответственного</option>
                    {props.options.map((item, index) => (
                        <option
                            key={props.name + index}
                            data-id={item.id}
                            value={item.id}
                            // onClick={() => console.log(item.representatives[item.id.split("_")[0]].docs)}
                        >
                            {item.name || item.full_name}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export default Select;