export function Price(value) {
    let answer = String(Math.round(value * 100) / 100);

    answer = answer.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

    if (!answer.includes('.')) {
        answer += '.00';
    } else {
        const [integerPart, decimalPart] = answer.split('.');
        answer = integerPart + '.' + (decimalPart.length === 1 ? decimalPart + '0' : decimalPart);
    }

    answer = answer.replace('.', ',');

    return answer;
}