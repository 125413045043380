import React, {useContext, useEffect, useState} from 'react';
import styles from "../dash.module.css";
import UserSection from "../../UI/Sections/UserSection";
import {Link, useNavigate} from "react-router-dom";
import UserContext from "../../../store/user/user-context";
import axios from "axios";
import Warnings from "../../UI/Sections/Warnings";
import Btn from "../../UI/Buttons/Btn";
import {Price} from "../../UI/Convertors/Price";
import {getTariffsCompanies} from "../../SendRequests/TariffsData";
import {postCompaniesTariffByIdCreateRequest} from "../../UI/GetInfo/getTariffsChanges";
import {Alert, Box, Button} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const Tenders = () => {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '2') navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = +window.localStorage.getItem('company');
    const project_id = +window.localStorage.getItem('project');
    const company = userSettings.companies.filter(c => c.id === company_id)[0];

    const [answers, setAnswers] = useState(null);
    const [tenders, setTenders] = useState(null);

    const [isNotEnoughLots, setIsNotEnoughLots] = useState(false);

    const publicTenderHandler = async (tender_id, state) => {
        // console.log(`\n `, tender_id, state);

        try {
            const formData = new FormData();
            formData.append('is_public', state);

            const response = await axios.post(
                `${userSettings.api}/tenders/companies/${company_id}/project/${project_id}/tenders/${tender_id}/change-publishing`,
                formData,
                {headers: {...userSettings.headers.post, 'Content-Type': 'multipart/form-data'}}
            );

            const updateTenders = [...tenders].map(tender => {

                if (tender.id === response.data.id) tender.is_public = response.data.is_public;

                return tender;
            });

            setTenders(updateTenders);

        } catch (error) {
            console.error('Error', error.response.data);
            // Обработка ошибки
        }
    }

    const handleNotEnoughTenders = async () => {
        try {
            const getTenderLimit = await axios.get(
                `${userSettings.api}/tenders/companies/${company_id}/project/${project_id}/check-tender-limits`,
                userSettings.headers.get
            );

            const canAdd = getTenderLimit.data.is_can;
            canAdd ? setIsNotEnoughLots(false) : setIsNotEnoughLots(true);
            if (canAdd) navigate("/work/coordinator/tender-add");
        } catch (error) {
            console.log(`\n check-tender-limits error`, error.response);
        }
    };

    const handleAddLotToTariff = async () => {
        try {
            const tariff = await getTariffsCompanies([company_id], userSettings);
            const totalAdded = tariff[0].tariff;
            console.log("текущее кол-во контрактов: ", totalAdded.contract.add);

            const body = {
                "add_investor": totalAdded.investor.add,
                "add_administrator": totalAdded.administrator.add,
                "add_coordinator": totalAdded.coordinator.add,
                "add_build_control": totalAdded.build_control.add,
                "add_manager": totalAdded.manager.add,
                "add_gip": totalAdded.gip.add,
                "add_contract": totalAdded.contract.add + 1
            }

            const postResponse = await postCompaniesTariffByIdCreateRequest(userSettings, company_id, body);
            console.log(postResponse);
        } catch (error) {
            console.log(error?.response?.status);
        }
    };


    // получить тендеры
    useEffect(() => {

        const getData = async () => {

            try {
                const getTenders = await axios.get(
                    `${userSettings.api}/tenders/companies/${company_id}/`,
                    userSettings.headers.get
                );
                const currentProjectTenders = getTenders.data.sort((a, b) => a.id - b.id).filter(t => t.project.id === project_id);
                // console.log(`\n currentProjectTenders`, currentProjectTenders);
                if (currentProjectTenders.length > 0) setTenders(currentProjectTenders);

                setAnswers(null);
            } catch (error) {
                setAnswers(<Warnings>
                    Ошибка получения информации - {error.response.data.errors ?
                    error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                    :
                    error.response.status
                }
                </Warnings>)
                console.log(`\n getData error`, error.response);
            }
        }

        getData();
    }, [company_id, project_id, userSettings]);

    // if (criteria) console.log(`\n criteria`, criteria);
    if (tenders) console.log(`\n tenders`, tenders);

    return (
        <UserSection>
            <h1>Тендеры</h1>
            {answers && answers}
            {company && <div className={`p-2`}>
                <div className={`text-xl`}>Информация о компании {company.name}</div>
                <div className={styles.list}>
                    {company.inn && <div className={styles.items}>ИНН {company.inn}</div>}
                    {company.kpp && <div className={styles.items}>КПП {company.kpp}</div>}
                </div>
            </div>}

            <Btn onClick={handleNotEnoughTenders} method={`add`} color={`button`}>
                Добавить тендер
            </Btn>
            {isNotEnoughLots &&
                <Box className='mt-4'>
                    <Alert variant="outlined" severity="warning">Вы превысили допустимый лимит договоров с
                        подрядчиками. Пожалуйста, обратитесь к администратору для дальнейших действий..</Alert>
                    <Box className='mt-4'>
                        <Button
                            className={'mt-4'}
                            onClick={handleAddLotToTariff}
                            variant="contained"
                            endIcon={<SendIcon/>}
                        >
                            Создать запрос на получение дополнительного слота тендер
                        </Button>
                    </Box>
                </Box>
            }

            {tenders ?
                <div className={`flex flex-wrap gap-2`}>
                    {tenders.filter(tender => tender.project.id === project_id).map(tender => <div
                        className={`${styles.card} flex flex-col`} key={tender.id}>
                        <div className={styles.label}>{tender.name}</div>
                        <div className={`px-2 shadow shadow-black/40 bg-pult/10`}>
                            <div
                                className={`grow my-auto`}>Тендер {tender.is_won ? "выигран" : !tender.is_public ? "не опубликован" : "опубликован"}</div>
                            {!tender.is_won && <Btn color={`button`}
                                                    onClick={() => publicTenderHandler(tender.id, !tender.is_public)}>{!tender.is_public ? "Опубликовать" : "Снять с публикации"}</Btn>}
                        </div>
                        <div className={`px-2 flex-1 flex-col flex`}>
                            <div>Раздел: {userSettings.tender_directions[tender.category]}</div>
                            <div>НМЦ лота: {Price(tender.nmc)}</div>
                            <div>Адрес объекта: {tender.address}</div>
                            <div>Дата и время окончания подачи
                                заявок: {new Date(tender.end_date).toLocaleDateString()} г.
                            </div>
                        </div>
                        <Btn method={`view`} color={`button`} center>
                            <Link to={`/work/coordinator/tender-info/${tender.id}`}>Детали тендера</Link>
                        </Btn>
                    </div>)}
                </div> :
                <Warnings info>Нет созданных тендеров</Warnings>
            }
        </UserSection>
    );
}

export default Tenders;