import axios from "axios";

export const paymentCard = async (userSettings, sum, company_id) => {

    try {
        // console.log('\n ', userSettings, sum, company_id);


        const response = await axios.post(
            `${userSettings.api}/company/${company_id}/card_payment/`,
            {amount: sum},
            userSettings.headers.post
        );
        if (response.data) {
            // console.log('\n paymentCard response', response.data);

            return {
                success: true,
                message: 'Оплата успешно проведена.'
            };
        }
    }
    catch (error) {
        console.log('\n paymentCard', error);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
}