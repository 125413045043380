import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import UserContext from "../../../store/user/user-context";
import {getSubcompanies} from "../../UI/GetInfo/getSubcompanies";
import UserSection from "../../UI/Sections/UserSection";
import axios from "axios";
import TheadSort from "../../UI/Tables/TheadSort";
import tables from "../../UI/Tables/table.module.css";
import Btn from "../../UI/Buttons/Btn";
import * as XLSX from "xlsx";
import Warnings from "../../UI/Sections/Warnings";

export default function ConsolidatedEstimateCalculation() {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '4') navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = window.localStorage.getItem('company');
    const project_id = window.localStorage.getItem('project');

    const [subcompanies, setSubcompanies] = useState(null);
    const [workJournals, setWorkJournals] = useState(null);

    const estimateNames = [
        {
            tag: "tender_id",
            name: "Тендер",
        },
        {
            tag: "subcompany_id",
            name: "Подрядчик",
        },
        {
            tag: "number",
            name: "№",
        },
        {
            tag: "ssr",
            name: "ССР",
        },
        {
            tag: "name_works",
            name: "Наименование работ",
        },
        {
            tag: "units",
            name: "Ед. изм.",
        },
        {
            tag: "value",
            name: "Объем",
        }
    ];

    const exportXlSX = () => {

        if (workJournals.length > 0) {

            const exportTable = [
                estimateNames.map(n => n.name)
            ];
            workJournals.forEach(w => {

                const row = estimateNames.map(n => {

                    return (n.tag === "tender_id" ?
                        subcompanies && subcompanies.find(s => s.tender.id === w[n.tag]).tender.name
                        :
                        n.tag === "subcompany_id" ?
                            subcompanies && subcompanies.find(s => s.subcompany === w[n.tag]).info.name
                            :
                            w[n.tag]);
                });

                // console.log(`\n  row`, row);
                exportTable.push(row);
            })
            // console.log(`\n exportTable`, exportTable);
            const sheets = [
                {
                    name: `ССР по состоянию на ${new Date().toLocaleDateString()}`,
                    data: exportTable
                }
            ];
            const wb = XLSX.utils.book_new();
            sheets.forEach(sheet => {
                const ws = XLSX.utils.aoa_to_sheet(sheet.data);
                XLSX.utils.book_append_sheet(wb, ws, sheet.name);
            });

            const filename = `Сводно сметный расчет по состоянию на ${new Date().toLocaleDateString()}.xlsx`;
            XLSX.writeFile(wb, filename);
        }
    };

    useEffect(() => {

        const getInfo = async () => {

            const subcompanies = await getSubcompanies(userSettings, company_id, project_id);
            // console.log('\n subcompanies', subcompanies);
            setSubcompanies(subcompanies);
            const all_work_journals = await axios.get(
                `${userSettings.api}/work-journal/companies/${company_id}/projects/${project_id}/all-work-journals`,
                userSettings.headers.get
            )
            // console.log('\n all_work_journals', all_work_journals.data);
            const updateWorkJournals = all_work_journals.data.flatMap(journal => {

                const estimate = journal.estimate.map(e=> ({
                    ...e,
                    journal_id: journal.id,
                    subcompany_id: journal.subcompany,
                    tender_id: journal.tender
                }));

                return (estimate);
            })
            // console.log('\n updateWorkJournals', updateWorkJournals);
            setWorkJournals(updateWorkJournals);
        }

        getInfo();
    }, [userSettings, company_id, project_id]);

    return (
        <UserSection>
            <h1>Сводный сметный расчёт (ССР)</h1>
            <Btn method={`xls`} color={`button`} onClick={exportXlSX}>Скачать в Excel</Btn>
            {workJournals &&
                <div className={`${tables.container} grow`}>
                    <table className={tables.table}>
                        <TheadSort
                            array={workJournals}
                            names={estimateNames}
                            setArray={setWorkJournals}
                        />
                        <tbody>
                        {workJournals.map(w => {

                            return (
                                <tr key={`${w.journal_id}_${w.id}`}>
                                    {estimateNames.map(n => {

                                        const work =
                                            n.tag === "tender_id" ?
                                                subcompanies && subcompanies.find(s => s.tender.id === w[n.tag])?.tender?.name
                                                :
                                                n.tag === "subcompany_id" ?
                                                    subcompanies && subcompanies.find(s => s.subcompany === w[n.tag])?.info?.name
                                                    :
                                                    n.tag === "ssr" ?
                                                        w[n.tag] || <Warnings>Нет данных</Warnings>
                                                        :
                                                        w[n.tag]
                                        ;

                                        return (
                                            <td
                                                key={n.tag}
                                                className={`${((n.tag !== "name_works" && n.tag !== "tender_id" && n.tag !== "subcompany_id")&& n.tag !== "subcompany") ? "text-center" : ""}`}
                                            >
                                                {work}
                                            </td>
                                        );
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            }
        </UserSection>
    );
}