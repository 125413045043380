import React, { useEffect, useState } from "react";
import ViewingDrawings from "./ViewingDrawings";
import { ReactComponent as Create } from "../../UI/Icons/Plan/created.svg";
import { ReactComponent as Send } from "../../UI/Icons/Plan/send.svg";
import { ReactComponent as Sign } from "../../UI/Icons/Plan/sing.svg";
import { ReactComponent as Call } from "../../UI/Icons/Plan/call.svg";
import { ReactComponent as Reject } from "../../UI/Icons/Plan/reject.svg";
import { ReactComponent as Apply } from "../../UI/Icons/Plan/accepted.svg";
import { ReactComponent as Gip } from "../../UI/Icons/Plan/gip.svg";
import statusConfig from "../../Work/ConstructionControl/statuses_config.json";

export default function ViewMap(
    {
        selectedSection,
        selectedFloor,
        fileServer,
        orders,
        isMoveOrder,
        isCreateOrder,
        setIsCreateOrder,
        subcompanies,
        createOrderHandler,
        sendOrder,
        selectedOrder,
        rejectOrder,
        acceptOrder,
        isCreateGipOrder,
        setIsCreateGipOrder,
        sendGipOrderHandler,
        gipOrders,
        editGipOrderHandler,
        moveOrderHandler,
        isMoveGipOrder,
        setNewCoordinate,
        editOrder,
        allIdForQueries,
        setOrders,
        setGipOrders
    }
) {
    // console.log('\n currentOrders', currentOrders);

    const [selectedRange, setSelectedRange] = useState(null);
    const [orderStatuses, setOrderStatuses] = useState(null);
    const statusesHistory = {
        1: <Create />,
        2: <Send />,
        3: <Sign />,
        5: <Call />,
        4: <Reject />,
        6: <Apply />
    };

    useEffect(() => {
        const updateSelectedRange = selectedSection?.ranges?.find((r) =>
            r.floors.includes(selectedFloor)
        );
        setSelectedRange(updateSelectedRange);
    }, [selectedSection.ranges, selectedFloor, selectedSection]);
    useEffect(() => {
        if (orders?.length > 0) {

            const currentOrders = orders?.filter(order => (order.floor === selectedFloor && order.section === selectedSection.id));
            // console.log(`\n orders`, orders);
            const updateOrderStatuses = [...new Set (currentOrders?.map(order => order.history[order.history.length - 1].status))].sort();
            // console.log(`\n updateOrderStatuses`, updateOrderStatuses);
            setOrderStatuses(updateOrderStatuses);
        }
    },[orders, selectedFloor, selectedSection]);

    // console.log('\n orders', orders);
    // console.log('\n selectedSection', selectedSection.id);
    // console.log('\n selectedFloor', selectedFloor);

    if (selectedRange && fileServer) return (<React.Fragment>
        {(orderStatuses?.length > 0) &&
            <div className={`absolute bottom-2 right-2 z-[9999999] border-pult border rounded-md bg-white/80 p-2 text-xs md:text-base`}>
                {orderStatuses.map(s =>
                    <div key={s} className={`flex gap-2 items-center my-1`}>
                        <div className={`w-5 rounded-full border-pult border`}>
                            {statusesHistory[s]}
                        </div>
                        <div>{statusConfig[s].label}</div>
                    </div>
                )}
            </div>
        }
        {(gipOrders && gipOrders.length > 0) &&
            <div className={`absolute bottom-2 right-2 z-[9999999] border-pult border rounded-md bg-white/80 p-2 text-xs md:text-base`}>
                <div className={`flex gap-2 items-center my-1`}>
                    <div className={`w-5 rounded-full border-pult border`}>
                        {<Gip />}
                    </div>
                    <div>Задача ГИП</div>
                </div>
            </div>
        }
        <ViewingDrawings
            imageUrl={`${fileServer}${selectedRange.plan}`}
            orders={orders?.filter(order => (order.floor === selectedFloor && order.section === selectedSection.id))}
            fileServer={fileServer}
            isCreateOrder={isCreateOrder}
            setIsCreateOrder={setIsCreateOrder}
            subcompanies={subcompanies}
            createOrderHandler={createOrderHandler}
            sendOrder={sendOrder}
            qualityControlOrder={selectedOrder}
            rejectOrder={rejectOrder}
            acceptOrder={acceptOrder}
            isCreateGipOrder={isCreateGipOrder}
            setIsCreateGipOrder={setIsCreateGipOrder}
            sendGipOrderHandler={sendGipOrderHandler}
            gipOrders={gipOrders?.filter(order => order.floor === selectedFloor )}
            editGipOrderHandler={editGipOrderHandler}
            moveOrderHandler={moveOrderHandler}
            isMoveOrder={isMoveOrder}
            isMoveGipOrder={isMoveGipOrder}
            setNewCoordinate={setNewCoordinate}
            editOrder={editOrder}
            allIdForQueries={allIdForQueries}
            setOrders={setOrders}
            setGipOrders={setGipOrders}
        />
    </React.Fragment>);
}