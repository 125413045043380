import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../../store/user/user-context";
import RouteScheme from "./RouteScheme.json"
import ControlItem from "./ControlItem";
import axios from "axios";
import {getCompany} from "../../UI/GetInfo/getCompany";
import {getOrders} from "../../UI/GetInfo/getOrders";
import {getTariffsRequests} from "../../UI/GetInfo/getTariffsRequests";
import {getQualityDocsList} from "../../SendRequests/QualityDocumentsData";
import {getSubcomanyList} from "../../SendRequests/workJournalData";

const ProjectControl = (props) => {

    const userSettings = useContext(UserContext);
    const currentRole = +window.localStorage.getItem('role');
    const company_id = +window.localStorage.getItem('company');
    const tender_id = +window.localStorage.getItem('tender');
    const project_id = +window.localStorage.getItem('project');

    const currentRoleSettings = RouteScheme.find(route => route.role === currentRole);

    const [companyContracts, setCompanyContracts] = useState(null);
    const [companiesInfo, setCompaniesInfo] = useState(null);
    const [subcontractorsWithoutSsr, setSubcontractorsWithoutSsr] = useState(null);

    const [isLockedWorkJournal, setIsLockedWorkJournal] = useState(false);

    const [calledOrders, setCalledOrders] = useState(null);

    const [tariffRequests, setTariffRequests] = useState(null);
    const [showOnlyTariffTab, setShowOnlyTariffTab] = useState(false);

    const [qualityDocs, setQualityDocs] = useState(null);
    const [currentContract, setCurrentContract] = useState(null);
    const [approvedQualityDocs, setApprovedQualityDocs] = useState(null);

    useEffect(() => {

        const registerContractInfo = async () => {
            try {
                const subcompaniesResponse = await axios.get(
                    `${userSettings.api}/company/${company_id}/subcompanies`,
                    userSettings.headers.get
                );
                // console.log(`\n subcompaniesResponse`, subcompaniesResponse.data.data);

                const subcompaniesAcceptResponse = await axios.get(
                    `${userSettings.api}/company/${company_id}/subcompanies-to-accept`,
                    userSettings.headers.get
                );
                // console.log(`\n subcompaniesAcceptResponse`, subcompaniesAcceptResponse.data);
                const contractsWithoutAccess = subcompaniesResponse.data.data.filter(s => !s.agreement_document);
                // console.log(subcompaniesResponse,subcompaniesAcceptResponse)
                // console.log(`\n contractsWithoutAccess`, contractsWithoutAccess);
                setCompanyContracts(contractsWithoutAccess.length > 0 && contractsWithoutAccess);
            } catch (error) {
                console.error('Error fetching contracts:', error);
            }
        };
        const subcontractorsWithoutSsr = async () => {
            try {
                // const subcompaniesResponse = await axios.get(
                //     `${userSettings.api}/company/${company_id}/subcompanies`,
                //     userSettings.headers.get
                // );
                // console.log(`\n subcompaniesResponse`, subcompaniesResponse.data.data);
                const allTenders = await axios.get(
                    `${userSettings.api}/tenders/companies/${company_id}/`,
                    userSettings.headers.get
                );
                // console.log(`\n allTenders`, allTenders.data);
                const tenderIds = allTenders.data.filter(tender => tender.project.id === project_id).map(tender => tender.id);
                // console.log(`\n tenderIds`, tenderIds);

                const subcontractorsWithoutSsr = tenderIds.map(async (tender_id) => {

                    const response = await axios.get(
                        `${userSettings.api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcontractors-without-ssr`,
                        userSettings.headers.get
                    );

                    if (response?.data.length > 0) return ({
                        subcompany: response.data[0],
                        tender: allTenders.data.find(t => t.id === tender_id).name,
                        tender_id,
                    });
                });
                const allSubcontractorsWithoutSsr = [...await Promise.all(subcontractorsWithoutSsr)].filter(a => a);
                // console.log(`\n allSubcontractorsWithoutSsr`, allSubcontractorsWithoutSsr);
                if (allSubcontractorsWithoutSsr.length > 0) setSubcontractorsWithoutSsr(allSubcontractorsWithoutSsr);
            } catch (error) {
                console.error('Error fetching subcontractors Without Ssr:', error);
            }
        };
        const unapprovedWorkLog = async () => {

            try {

                const wonTenderLots = await axios.get(
                    `${userSettings.api}/subcompanies/${company_id}/won-tender-lots`,
                    userSettings.headers.get
                );
                // console.log(`\n wonTenderLots`, wonTenderLots.data);

                const currentTender = wonTenderLots.data.find(lot => lot.tender.id === tender_id);
                // console.log(currentTender)
                setCurrentContract(currentTender);
                // console.log(`\n currentTender`, currentTender,tender_id);

                // const buildings = await axios.get(
                //     `${userSettings.api}/tep-building/companies/${main_company_id}/projects/${project_id}/buildings/`,
                //     userSettings.headers.get
                // );
                // console.log(`\n buildings`, buildings.data.data);

                // const getSections = buildings.data.data.map(async (b) => {
                //
                //     const building_id = b.id;
                //
                //     const response = await axios.get(
                //         `${userSettings.api}/plans-configurator/companies/${main_company_id}/projects/${project_id}/building/${building_id}/sections/`,
                //         userSettings.headers.get
                //     );
                //
                //     return(response.data)
                // });
                // const sections = (await Promise.all(getSections)).flat();
                // console.log(`\n sections`, sections);

                // const getPermission = sections.map( async (s) => {
                //
                //     const building_id = s.building;
                //     const section_id = s.id;
                //
                //     const response = await axios.get(
                //         `${userSettings.api}/work_payment/companies/${main_company_id}/projects/${project_id}/buildings/${building_id}/sections/${section_id}/permission`,
                //         userSettings.headers.get
                //     );
                //
                //     return(response.data)
                // })
                // const permission = (await Promise.all(getPermission)).flat();
                // console.log(`\n permission`, permission);

                // const falseOrders = permission
                //     .filter(item => !item.permission)
                //     .flatMap(item => item.orders.map(order => order.id));
                // console.log(`\n falseOrders нужно уточнить проверку`, falseOrders);

                const estimateElements = await axios(`${userSettings.api}/work-journal/companies/${currentTender.contract.main}/projects/${currentTender.contract.project_id}/tenders/${currentTender.contract.tender}/subcompanies/${currentTender.contract.subcompany}/estimate-elements/`, userSettings.headers.get);
                setIsLockedWorkJournal(estimateElements.data.is_locked);
            } catch (error) {
                console.error('Error unapproved Work Log:', error.response);
            }
        }
        const getPermission = async () => {

        }
        const callToOrders = async () => {
            const orders = await getOrders(userSettings, company_id, project_id);
            if (orders.answer) {
                console.log('\n orders', orders);
                return;
            }
            const updateCalledOrders = orders?.filter(o => o.history[o.history.length - 1].status === 5);
            // console.log('\n updateCalledOrders', updateCalledOrders);
            setCalledOrders(updateCalledOrders);
        }
        const resolveTariffRequests = async () => {
            try {
                const companyTariffRequests = await getTariffsRequests(userSettings);
                const onlyNewTariff = companyTariffRequests.filter(tariff => tariff.status === "new")

                setShowOnlyTariffTab(true);

                setTariffRequests(onlyNewTariff.length > 0 && onlyNewTariff);
            } catch (error) {
                console.error('Error fetching companyTariffRequests:', error);
            }
        }
        const getQualityDocs = async () => {
            const subcompanies = await getSubcomanyList(userSettings, company_id, project_id);

            if (subcompanies?.data?.length > 0) {

                // console.log('\n subcompanies', subcompanies.data);

                const getQualityDocs = subcompanies?.data?.map(async (s) => {

                    const response = await getQualityDocsList(userSettings, company_id, project_id, s.subcompany, s.tender);

                    return (response);
                });
                const prepareQualityDocs = await Promise.all(getQualityDocs);
                const updateQualityDocs = prepareQualityDocs
                    .filter(d => d && d.documents)
                    .filter(d => {
                        const roleChecks = {
                            4: doc => doc.is_manager_approved === false,
                            3: doc => doc.is_build_control_approved === false
                        };

                        return roleChecks[currentRole]
                            ? d.documents.some(roleChecks[currentRole])
                            : false;
                    })
                ;
                // console.log('\n updateQualityDocs', updateQualityDocs);
                setQualityDocs(updateQualityDocs);
            }
        }

        const getApprovedQualityDocs = async () => {
            if (!currentContract) return;

            const docs = await axios.get(
                `${userSettings.api}/work_payment/companies/${currentContract.contract.main}/projects/${currentContract.contract.project_id}/tenders/${currentContract.contract.tender}/subcompanies/${currentContract.contract.subcompany}/quality-docs`,
                userSettings.headers.get
            );

            const response = docs.data;
            // console.log("response, ", response)

            const approvedDocs = response.filter(doc => doc.is_build_control_approved && doc.is_manager_approved && !doc.is_approved);
            setApprovedQualityDocs(approvedDocs);
        }

        if (currentRole === 0) resolveTariffRequests();
        if (currentRole === 1) resolveTariffRequests();

        if (currentRole === 2) registerContractInfo();
        if (currentRole === 4) {

            subcontractorsWithoutSsr();
            getQualityDocs();
        }
        if (currentRole === 6) {
            const getCurrentContract = async () => {
                const wonTenderLots = await axios.get(
                    `${userSettings.api}/subcompanies/${company_id}/won-tender-lots`,
                    userSettings.headers.get
                );

                const currentTender = wonTenderLots.data.find(lot => lot.tender.id === tender_id);
                setCurrentContract(currentTender);
            }

            getCurrentContract();


            getApprovedQualityDocs()
            unapprovedWorkLog();
            getPermission();
        }
        if (currentRole === 3) {

            callToOrders();
            getQualityDocs();
        }

        // console.log(`\n `, currentRole);
    }, [userSettings, company_id, currentRole, currentContract?.contract?.main]);
    useEffect(() => {

        if (companyContracts) {
            const subcompanyIdList = [...new Set(companyContracts.map(c => c.subcompany))];
            // console.log(`\n subcompanyIdList`, subcompanyIdList);
            getCompany(subcompanyIdList, userSettings)
                .then(results => {
                    // console.log("Результаты получены:", results);
                    setCompaniesInfo(results);
                })
                .catch(error => {
                    console.error("Произошла ошибка при получении компаний:", error)
                });
        }
    }, [companyContracts, userSettings]);
    // console.log(approvedQualityDocs)
    return (
        <React.Fragment>
            {approvedQualityDocs?.length > 0 && <ControlItem
                id={`approved-docs`}
                state={props.state}
                hasApprovedDocs={approvedQualityDocs?.length > 0}
            />}
            {showOnlyTariffTab && <ControlItem
                id={`tariff-requests`}
                state={props.state}
                showOnlyTariffTab={showOnlyTariffTab}
                tariffRequests={tariffRequests}
                currentRole={currentRole}
                companiesInfo={companiesInfo}
                shownList={props.shownList}
                showListHandler={props.showListHandler}
            />}
            {companyContracts && <ControlItem
                id={`contracts`}
                state={props.state}
                contracts={companyContracts}
                companiesInfo={companiesInfo}
                shownList={props.shownList}
                showListHandler={props.showListHandler}
            />}
            {subcontractorsWithoutSsr && <ControlItem
                id={`withoutSsr`}
                subcontractorsWithoutSsr={subcontractorsWithoutSsr}
                state={props.state}
                shownList={props.shownList}
                showListHandler={props.showListHandler}
            />}
            {isLockedWorkJournal && <ControlItem
                id={`notApprovedWorkLog`}
                isLockedWorkJournal={isLockedWorkJournal}
                state={props.state}
                shownList={props.shownList}
                showListHandler={props.showListHandler}
            />}
            {calledOrders && <ControlItem
                id={`calledOrders`}
                calledOrders={calledOrders}
                state={props.state}
                shownList={props.shownList}
                showListHandler={props.showListHandler}
            />}
            {qualityDocs && <ControlItem
                id={`qualityDocs`}
                qualityDocs={qualityDocs}
                state={props.state}
                shownList={props.shownList}
                showListHandler={props.showListHandler}
            />}
            {(currentRoleSettings && currentRoleSettings.routes.length > 0) && currentRoleSettings.routes.map((route, id) => (
                <ControlItem
                    key={id}
                    id={id}
                    href={`${route.route}`}
                    label={route.label}
                    shownList={props.shownList}
                    showListHandler={props.showListHandler}
                    state={props.state}
                />
            ))}
        </React.Fragment>
    );
}

export default ProjectControl;