import {pdf, PDFDownloadLink} from "@react-pdf/renderer";
import AktOsvidetelstvovaniya from "../../../UI/PdfDocuments/Akt-Osvidetelstvovaniya";
import Select from "../../../UI/Select/Select";
import useFetchedInfo from "./hooks/useFetchedInfo";
import useMixedSelect from "./hooks/useMixedSelect";
import {validateSelect} from "./helpers/validateSelect";
import useRepresentativeSelect from "./hooks/useRepresentativeSelect";
import {FormProvider, useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import Input from "../../../UI/Inputs/Input";
import {Alert, Button, Typography} from "@mui/material";

const GenerateAktOsvidetelstvovaniyaPDF = ({setAktOsv}) => {
    const subcompany_id = +window.localStorage.getItem('company');
    const tender_id = +window.localStorage.getItem('tender');

    const {fetchedInfo, isLoading} = useFetchedInfo(subcompany_id, tender_id);

    const [representative_id, setRepresentative_id] = useState(null);
    const [representativePerson, setRepresentativePerson] = useState(null)

    const handleChangeRepresentatives = (e) => {
        setRepresentative_id(e.target.value);
        setRepresentativePerson(fetchedInfo.representatives.find(r => r.id === +e.target.value))
    }

    // ------------------------------------------------------------------------------------------

    const {
        mixedState,
        isSelectedMixed,
        handleMixedOnChange,
    } = useMixedSelect(fetchedInfo?.personsList);

    const {
        representativesState,
        isSelectedRepresentatives,
        handleRepresentativesOnChange,
    } = useRepresentativeSelect(fetchedInfo?.personsList);

    // ------------------------------------------------------------------------------------------

    const methods = useForm({
        defaultValues: {
            first: "",
            second: "",
            fourth: "",
            sixth: "",
            eighth: "",
            additional_info: "",
            copies: "",
            attachments: "",
        }
    });
    const {register, handleSubmit, formState: {errors}} = methods;

    const [submitted, setSubmitted] = useState(false);

    const handleSubmitCallback = async (data, e) => {
        e.stopPropagation();
        e.preventDefault();
        console.log(data)
        setSubmitted(true);

        // Process form data here if needed

        // Example: send form data to server or perform actions
        // await sendFormDataToServer(formData);
    };

    const formData = methods.getValues();

    // ------------------------------------------------------------------------------------------

    const isPDFVisible =
        !isLoading &&
        isSelectedMixed &&
        isSelectedRepresentatives &&
        representativePerson &&
        submitted;

    useEffect(() => {
        if (!isPDFVisible) return;

        const blob = pdf((<AktOsvidetelstvovaniya
            fetchedInfo={fetchedInfo}
            mixedState={mixedState}
            representativesState={representativesState}
            representativePerson={representativePerson}
            formData={formData}
        />)).toBlob().then(blob => setAktOsv(new File([blob], "Акт Освидетельствования.pdf")))

    }, [isPDFVisible]);

    return (
        <div>
            {!isLoading && fetchedInfo &&
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(handleSubmitCallback)}>
                        <Typography variant="h1">Акт освидетельствования ответственных конструкций:</Typography>
                        <Alert className="w-fit" severity="info">Выбор недоступен, если у представителя отсутствуют
                            доверенность и / или свидетельство о допуске</Alert>
                        <div className='space-y-4 m-3 p-3 bg-[#e4e4e7] rounded-lg'>
                            {/*<Button onClick={sendAktOsvid}>send akt-osvid</Button>*/}
                            <p>Застройщик или технический заказчик:</p>
                            <Select
                                name='customer_id'
                                handleOnChange={handleMixedOnChange}
                                options={validateSelect(fetchedInfo.personsList.filter(p => p.person === 1 || p.person === 2))}
                            />

                            <p>Лицо, осуществляющее строительство:</p>
                            <Select
                                name='builder_id'
                                handleOnChange={handleMixedOnChange}
                                options={validateSelect(fetchedInfo.personsList.filter(p => p.person === 4))}
                            />

                            <p>Лицо, осуществляющее подготовку проектной документации:</p>
                            <Select
                                name='documentation_id'
                                handleOnChange={handleMixedOnChange}
                                options={validateSelect(fetchedInfo.personsList.filter(p => p.person === 5))}
                            />

                            <p>Лицо, выполнившее работы, подлежащие освидетельствованию:</p>
                            <Select
                                name='inspection_id'
                                handleOnChange={handleChangeRepresentatives}
                                options={fetchedInfo.representatives}
                                not_combined={true}
                            />

                            {/*------------------------------------------------------*/}

                            <p>Представитель лица, осуществляющего строительство, по вопросам строительного
                                контроля:</p>
                            <Select
                                name='builder_control_id'
                                handleOnChange={handleRepresentativesOnChange}
                                options={validateSelect(fetchedInfo.personsList.filter(p => p.person === 4))}
                            />

                            <p className="text-xl font-bold">Необязательные лица:</p>

                            <p>а также иные представители лиц, участвующих в освидетельствовании:</p>
                            <Select
                                name='other_inspection_id'
                                handleOnChange={handleRepresentativesOnChange}
                                options={validateSelect(fetchedInfo.personsList)}
                            />

                            <Select
                                name='other_inspection_id_2'
                                handleOnChange={handleRepresentativesOnChange}
                                options={validateSelect(fetchedInfo.personsList)}
                            />

                            <div className='w-[400px]'>
                                <p>1. К освидетельствованию предъявлены следующие работы:</p>
                                <Input
                                    error={errors?.first?.message} {...register("first", {required: "Заполните поле"})}/>
                                <p>2. Работы выполнены по проектной документации</p>
                                <Input
                                    error={errors?.second?.message} {...register("second", {required: "Заполните поле"})}/>
                                <p>4. Предъявлены документы, подтверждающие соответствие работ предъявляемым требованиям:</p>
                                <Input
                                    error={errors?.fourth?.message} {...register("fourth", {required: "Заполните поле"})}/>
                                <p>6. Работы выполнены в соответствии с</p>
                                <Input
                                    error={errors?.fifth_a?.message} {...register("sixth", {required: "Заполните поле"})}/>
                                <p>7. Разрешается производство последующих работ</p>
                                <Input
                                    error={errors?.fifth_b?.message} {...register("seventh", {required: "Заполните поле"})}/>

                                <p>Дополнительные сведения</p>
                                <Input
                                    error={errors?.additional_info?.message} {...register("additional_info", {required: "Заполните поле"})}/>
                                <p>Акт составлен в n экземплярах</p>
                                <Input
                                    error={errors?.copies?.message} {...register("copies", {required: "Заполните поле"})}/>
                                <p>Приложения:</p>
                                <Input
                                    error={errors?.attachments?.message} {...register("attachments", {required: "Заполните поле"})}/>
                            </div>

                            <Button
                                type={'submit'}
                                className='w-full'
                                variant="contained"
                            >Сформировать документ</Button>
                        </div>
                    </form>
                </FormProvider>
            }
            {isPDFVisible &&
                <div>
                    <PDFDownloadLink
                        document={
                            <AktOsvidetelstvovaniya
                                fetchedInfo={fetchedInfo}
                                mixedState={mixedState}
                                representativesState={representativesState}
                                representativePerson={representativePerson}
                                formData={formData}
                            />}
                        fileName={'Akt-osvidetelstvovaniya-otvetstvennyh-konstrukciy'}
                    >
                        <Button variant="contained">Готово к загрузке!</Button>
                    </PDFDownloadLink>

                    {/*<PDFViewer>*/}
                    {/*    <AktOsvidetelstvovaniya*/}
                    {/*        fetchedInfo={fetchedInfo}*/}
                    {/*        mixedState={mixedState}*/}
                    {/*        representativesState={representativesState}*/}
                    {/*        formData={formData}*/}
                    {/*    />*/}
                    {/*</PDFViewer>*/}
                </div>
            }
        </div>
    )
};

export default GenerateAktOsvidetelstvovaniyaPDF;
